import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Field } from "redux-form";
import GoogleAutocomplete from "../../common/FormFields/googlePlaceAutocomplete";
import { GridContainer, StyledLoadingButton, StyledDialog } from "./styles";
import ReduxFormTextField from "../../common/FormFields/TextField";
import {
  required,
  validateDate,
  validateGoogleOrigin,
} from "../../common/FormFields/validations";
import ReduxDatePicker from "../../common/FormFields/DatePicker";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Close from "@material-ui/icons/Close";
import { PRIVACY_OPTIONS } from "../../constants";
import { axiosConfig, axiosInst } from "../../axios";
import { Input } from "@material-ui/core";
import { getCoowner } from "../duck";

import { ReactComponent as DeleteIcon } from '../../assets/planner/delete.svg'

const NewTourDialogPure = (props) => {
  const {
    submitting,
    handleSubmit,
    fullScreen,
    heading,
    getCoownerProfile,
    userList,
    setCoowner,
    subheading,
    buttonText,
    handleClose,
    initialValues,
    open,
    lat,
    lng,
  } = props;

  const [coownerList, setCoownerList] = useState([]);
  const [fetchData, setFetchData] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    console.log(initialValues);
    setCoownerList(initialValues?.coowners || []);
    setCoowner(initialValues?.coowners || []);
  },[initialValues])

  useEffect(() => {
    const temp = userList?.filter((elem, pos) => {
      return userList.findIndex(f => f.user_id == elem.user_id) == pos;
    });
    if(inputValue !== '') {
      console.log('setting fetch', inputValue);
      setFetchData(temp || []);
    } else {
      console.log('setting blank');
      setFetchData([]);
    }
  }, [userList]);
  const searchName = (name) => {
    console.log(name);
    if (name == '') {
      setFetchData([]);
    }
    setInputValue(name);
    getCoownerProfile(name);
    console.log(userList);
  };

  const manageUser = (user) => {
    const temp = [...coownerList];
    temp.push({ ...user, trip_id: initialValues?.trip_id || null, role: 'viewer' });
    setCoownerList(temp);
    setCoowner(temp);
    setInputValue('');
    setFetchData([]);
    console.log(coownerList);
  }

  const removeCoo = (user) => {
    const index = coownerList.findIndex(u => u.user_id == user.user_id);
    const temp = [...coownerList];
    temp.splice(index, 1);
    setCoownerList(temp);
    setCoowner(temp);
  }

  const manageRole = (user, role) => {
    const index = coownerList.findIndex(u => u.user_id == user.user_id);
    const temp = [...coownerList];
    temp[index].role = role;
    console.log(temp);
    setCoownerList(temp);
    setCoowner(temp);
  }

  return (
    <StyledDialog fullScreen={fullScreen} open={open}>
      <DialogTitle className="dialog-title" disableTypography={true}>
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className="dialog-heading" color="secondary">
              {heading}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <form className="dialog-form" onSubmit={handleSubmit}>
        <Typography className="subheading" variant="caption">
          {subheading}
        </Typography>
        <GridContainer direction="row" spacing={4} container>
          <Grid item md={6} xs={12}>
            <Field
              name="name"
              component={ReduxFormTextField}
              validate={required}
              label="Trip Name"
              placeholder="Enter your trip name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              required
              name="origin"
              label="Origin"
              validate={validateGoogleOrigin}
              placeholder="Origin"
              position="fixed"
              component={GoogleAutocomplete}
              lat={lat}
              lng={lng}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="from_dt"
              validate={[validateDate, required]}
              fullWidth
              component={ReduxDatePicker}
              required
              label="Start Date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="to_dt"
              validate={[validateDate, required]}
              fullWidth
              required
              component={ReduxDatePicker}
              label="End Date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="type"
              label={"Privacy"}
              placeholder={"Privacy"}
              select
              component={ReduxFormTextField}
              validate={required}
              required
            >
              {PRIVACY_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field
              label={"Co-Owners"}
              value={inputValue}
              onChange={(e) => searchName(e.target.value)}
              placeholder={"Add Coowners"}
              component={ReduxFormTextField}
            ></Field>
            {fetchData.map((obj) => {
              console.log(obj.user_id);
              return (
              <MenuItem key={obj.user_id} value={obj.user_id} disabled={coownerList.findIndex(f => f.user_id == obj.user_id) !== -1} onClick={() => { manageUser(obj) }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      backgroundColor: "#ccc",
                      borderRadius: "50%",
                      marginRight: "5%",
                    }}
                    src={obj.prof_pic}
                  />
                  <div>
                    <p style={{ margin: 0 }}>{obj.username}</p>
                    <h6 style={{ color: "#fff", fontSize: "18px", margin: 0 }}>
                      {obj.first_name} {obj.last_name}
                    </h6>
                  </div>
                </div>
              </MenuItem>
            )})}
          </Grid>
          {coownerList.map(coo => {
            return (
              <Grid item xs={12}>
                <div
                  className="addCoownerCon"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: "#00000045",
                    padding: "10px",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "40%",
                    }}
                  >
                    <img
                      style={{
                        height: "50px",
                        width: "50px",
                        backgroundColor: "#ccc",
                        borderRadius: "50%",
                        marginRight: "5%",
                      }}
                      src={coo.prof_pic}
                    />
                    <div>
                      <p style={{ margin: 0 }}>{coo.username}</p>
                      <h6 style={{ color: "#fff", fontSize: "18px", margin: 0 }}>
                        {coo.first_name} {coo.last_name}
                      </h6>
                    </div>
                  </div>

                  <div
                    style={{

                      padding: "10px",
                      borderRadius: "15px",
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row'
                    }}
                  >
                    <StyledLoadingButton
                      variant="contained"
                      color={coo.role == 'viewer' ? "secondary" : "primary"}
                      type={"button"}
                      onClick={() => { manageRole(coo, 'viewer') }}
                    >
                      {'Viewer'}
                    </StyledLoadingButton>
                    <p>&nbsp;&nbsp;</p>
                    <StyledLoadingButton
                      variant="contained"
                      color={coo.role == 'editor' ? "secondary" : "primary"}
                      type={"button"}
                      onClick={() => { manageRole(coo, 'editor') }}
                    >
                      {'Editor'}
                    </StyledLoadingButton>
                    <p>&nbsp;&nbsp;</p>
                    <IconButton
                      className="coo-delete"
                      onClick={() => removeCoo(coo)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <StyledLoadingButton
              variant="contained"
              color="secondary"
              type={"submit"}
              submitting={submitting}
            >
              {buttonText}
            </StyledLoadingButton>
          </Grid>
        </GridContainer>
      </form>
    </StyledDialog>
  );
};

export default withMobileDialog()(NewTourDialogPure);
