import { reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { addnewuserApi } from '../services'
import { enqueueSnackbar } from '../../Master/Notifier/duck'

const adduserSubmit = (values, dispatch, props) => {
  const formData = {
    user_name: values.username,
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email,
    password: values.password,
    currency: 'INR',
    access_code: '30313', //values.access_code,
  }
  return addnewuserApi(formData)
    .then((res) => {
      if (res.data.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: 'User Added successfully.',
            variant: 'success',
          }),
        )
        //  props.history.push("./login");
        props.reset('AddUserPure')
        props.setIsOpen(false)
      } else {
        dispatch(
          enqueueSnackbar({
            message: res.data.message,
            variant: 'error',
          }),
        )
      }
    })
    .catch((err) => {
      console.log(err)
      dispatch(
        enqueueSnackbar({
          message: err.response.data.message,
          variant: 'error',
        }),
      )
    })
}

const AdduserHandlers = compose(
  reduxForm({
    form: 'adduser-form',
    onSubmit: adduserSubmit,
  }),
)

export default AdduserHandlers
