import React from "react";
import { connect } from "react-redux";
import {
  compose,
  withHandlers,
  lifecycle,
  branch,
  renderComponent,
  withState,
  withProps,
} from "recompose";
import { getFormValues } from "redux-form";
import { fetchTrip, fetchOtherTrip, getCoowner, setCoowner, getSpEvents } from "./duck";
import LoadingScreen from "../common/LoadingScreen";
import { SubmitCreateTrip } from "./CreateTripSubmit";
import { SubmitEditTrip } from "./EditTripSubmit";
import moment from "moment";
import { COMMUNITY_ID, MY_ID } from "../constants";

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    trip: state.trip,
    importTrip: state.importTrip,
    tripValues: getFormValues("trip-plan-form")(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTrip: (id) => dispatch(fetchTrip(id, MY_ID)),
    fetchOtherTrip: (id, type) => dispatch(fetchOtherTrip(id, type)),
    getCoownerProfile: (name) => dispatch(getCoowner(name)),
    setCoowner: (name) => dispatch(setCoowner(name)),
    getSpecialEventList: () => dispatch(getSpEvents()),
  };
};

const TourPlanHandlers = compose(
  withState("isDialogOpen", "setDialogOpen", false),
  withState("duration", "setDuration", []),
  withState("height", "setHeight", 300),
  withState("zIndex", "setZIndex", 1),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    submitCreateTrip: SubmitCreateTrip,
    submitEditTrip: SubmitEditTrip,
    handleRecomendationClick: (props) => (activity, type) => {
      const {
        trip: {
          tripdata: { from_dt, to_dt },
        },
        setZIndex,
      } = props;
      setZIndex(1);
      let searchString = "";
      if (type === "restaurant") {
        searchString = `Restaurants near ${activity.address}`;
      } else if (type === "hotel") {
        searchString = `Hotels near ${activity.address}`;
      } else if (type === "event") {
        searchString = `Events near ${activity.address} from ${moment(
          from_dt
        ).format("Do MMM")} to ${moment(to_dt).format("Do MMM YY")}`;
      } else if (type === "places") {
        searchString = `Places to visit near ${activity.address}`;
      } else {
        searchString = `Things to do near ${activity.address}`;
      }
      let elements = window.google.search.cse.element.getAllElements();
      Object.keys(elements).forEach((element) => {
        elements[element].execute(searchString);
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match: {
          params: { action },
        },
        fetchTrip,
        getSpecialEventList
      } = this.props;
      if (action === "edit" && this.props.match.params.id) {
        fetchTrip(this.props.match.params.id);
        getSpecialEventList();
      }
    },
  }),
  branch(
    (props) => {
      const {
        match: {
          params: { action },
        },
        trip,
      } = props;
      if (action === "edit" && props.match.params.id && trip.loading) {
        return true;
      } else {
        return false;
      }
    },
    renderComponent(() => <LoadingScreen inline />)
  ),
  withProps((props) => {
    let initialValues = {};
    const {
      match: {
        params: { action },
      },
    } = props;
    const {
      trip: { tripdata },
    } = props;
    if (action === "create") {
      initialValues = {
        plan: [
          {
            startingplace: "",
            type: COMMUNITY_ID,
            activities: [
              {
                activityname: "",
                activitytype: "",
                type: COMMUNITY_ID,
              },
            ],
          },
        ],
      };
    } else if (props.trip.data.length > 0) {
      initialValues = {
        plan: props.trip.data.map((day, index) => {
          const dayObj = {
            startingplace: {
              lat: +day.lat,
              lng: +day.long,
              address: day.place || " ",
              city: day.city,
            },
            type: day.access_type || MY_ID,
            activities: day.activitydata.map((activity, index) => {
              const activityObj = {
                activityname: {
                  lat: +activity.lat,
                  lng: +activity.long,
                  address: activity.place_from || " ",
                  city: activity.city,
                  activity_status: activity.activity_status,

                  pics: activity.pics,
                  comments: activity.comments,
                },
                to: {
                  lat: +activity.place_to_lat,
                  lng: +activity.place_to_long,
                  address: activity.place_to || " ",
                  city: activity.place_to_city,
                },
                activitytype: activity.type,
                activitycategory: activity.category,
                eventname: activity.event,
                activitycost: activity.activity_cost,
                note: activity.note,
                type: activity.access_type || MY_ID,
                docname: activity.docname || "",
              };
              return activityObj;
            }),
          };
          return dayObj;
        }),
      };
    } else {
      initialValues = {
        plan: [
          {
            startingplace: {
              lat: +tripdata.lat,
              lng: +tripdata.longi,
              address: tripdata.origin || " ",
              city: tripdata.city,
            },
            type: tripdata.trip_access_type || COMMUNITY_ID,
            activities: [
              {
                activityname: "",
                activitytype: "",
                activitycategory: "activity",
                // type: getPrivacyLevel(tripdata.trip_access_type)[0].value
              },
            ],
          },
        ],
      };
    }
    return { initialValues };
  })
);

export default TourPlanHandlers;
