import React from "react";
import { Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import TourPlan from "../TourPlan";
import LoginRoute from "./LoginRoute";
import AuthRoute from "./AuthRoute";
import Master from "../Master";
import Profile from "../Profile";
import TripExplorer from "../TripExplorer";
import { LandingPage } from "../LandingPage";
import { PROFILE_URL, USER_URL } from "../urls";
import NotFound from "../common/NotFound";
import { PAGE_NOT_FOUND, PAGE_NOT_FOUND_DESC } from "../constants";
import OnboardingProvider from "../Onboarding/OnboardingProvider";

const RoutesPure = (props) => {
  console.log("propsroutes:", props);
  return (
    <OnboardingProvider>
      <Master>
        <CssBaseline />
        <Switch>
          <LoginRoute
            exact
            path={["/", "/validatedLogin", "/set-password", "/login"]}
            render={(props) => <LandingPage {...props} />}
          />
          <AuthRoute
            exact
            path="/trip-explorer/:id"
            component={(props) => <TripExplorer {...props} />}
          />
          {props.auth.isLoggedIn ? (
            <AuthRoute
              exact
              path={[
                "/trip-explorer/:id/:type(following)",
                "/trip-explorer/:id/:type(community)",
              ]}
              component={(props) => <TripExplorer {...props} />}
            />
          ) : (
            <Route
              exact
              path={"/trip-explorer/:id/:type(following)"}
              render={(props) => <TripExplorer {...props} />}
            />
          )}

          {/* <AuthRoute
            exact
            path={[
              "/trip-explorer/:id/:type(following)",
              "/trip-explorer/:id/:type(community)",
            ]}
            component={(props) => <TripExplorer {...props} />}
          /> */}

          {/* <AuthRoute path={`${USER_URL}/:id`} component={Profile} /> */}
          <Route
            path={`${USER_URL}/:id`}
            render={(props) => <Profile {...props} />}
          />

          <AuthRoute path={PROFILE_URL} component={Profile} />
          <AuthRoute
            exact
            path={[
              "/trip-plan/:action(create)",
              "/trip-plan/:action(edit)/:id",
              "/trip-plan/:action(edit)/:id/:importType(community)/:tripId",
              "/trip-plan/:action(edit)/:id/:importType(following)/:tripId",
              "/trip-plan/:action(edit)/:id/:importType(import)",
            ]}
            component={(props) => <TourPlan {...props} />}
          />
          <Route
            render={() => (
              <NotFound
                errorMsg={PAGE_NOT_FOUND}
                description={PAGE_NOT_FOUND_DESC}
              />
            )}
          />
        </Switch>
      </Master>
    </OnboardingProvider>
  );
};

export default RoutesPure;
