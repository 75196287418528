import React, { useRef } from "react";
import PhotoCard from "../../../common/PhotoCard/PhotoCard";
import Typography from "@material-ui/core/Typography";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import LoadingButton from "../../../common/LoadingButton";
import AddImage from "./add-image.png";
import { ReactComponent as DocumentIcon } from "../../../assets/document.svg";
import { ReactComponent as ImportIcon } from "../../../assets/profile/import.svg";

import {
  DayPointer,
  DayHeadingContainer,
  ActivityPointer,
  ActivityContainer,
  ActivityName,
  ActivityPointerCotainer,
  StyledChecbox,
  UploadPhotos,
  DrivingTimeContainer,
  DrivingTime,
  StyledLink,
  CustomIconButton,
  Container,
} from "./styles";
import PhotosGrid from "../../PhotosGrid";
import NoContent from "../../../common/NoContent";
import GreenRoadIcon from "../../../assets/green-car.svg";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  DayColors,
  ActivityColors,
  getDurationExplorer,
  getImportActivity,
  getActivitySvg,
} from "../../../common/utils";
import moment from "moment";
import ImportTripDialog from "../../../common/ImportTripDialog/ImportTripDialog";
import NavigationIcon from "@material-ui/icons/Navigation";
import { Hidden } from "@material-ui/core";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import "./index.scss";
import Notes from "./notes";
import { StyledImg } from "../../../Profile/styles";

const getActivityType = (plan, currentActivityIndex, currentDayIndex) => {
  if (
    plan &&
    plan[currentDayIndex] &&
    plan[currentDayIndex].activitydata &&
    plan[currentDayIndex].activitydata[currentActivityIndex]
  ) {
    return plan[currentDayIndex].activitydata[currentActivityIndex].type;
  }
};

const getActivityName = (name, type, eventlabel) => {
  if (eventlabel) {
    const acName = name.split(",");
    if (acName.length > 2) {
      const newName = acName.splice(0, 2);
      return `${eventlabel} at ${newName.join(" ,")}`;
    }
    return `${eventlabel} at ${acName}`;
  }
  if (type == "dinner") {
    let actName = type + " - ";
    const jsonName = JSON.parse(name);
    console.log(jsonName);
    for (let i = 0; i < jsonName.length; i++) {
      console.log(jsonName[i].itemName);
      actName = actName + jsonName[i].itemName;
      if (i != jsonName.length - 1) {
        actName = actName + ", ";
      }
    }
    return actName;
  } else if (type == "lunch") {
    let actName = type + " - ";
    const jsonName = JSON.parse(name);
    console.log(jsonName);
    for (let i = 0; i < jsonName.length; i++) {
      console.log(jsonName[i].itemName);
      actName = actName + jsonName[i].itemName;
      if (i != jsonName.length - 1) {
        actName = actName + ", ";
      }
    }
    return actName + ", Salad, Papad, Aachar , Chaas, Roti(phulka)";
  } else if (type == "eveningSnacks") {
    let actName = type + " - " + name + ", Tea, coffee, biscuits";
    return actName;
  } else if (type == "breakfast") {
    let actName = type + " - " + name + ", Tea, coffee, bread butter jam";
    return actName;
  }
  const acName = name.split(",");
  if (acName.length > 2) {
    const newName = acName.splice(0, 2);
    return newName.join(" ,");
  }
  return acName;
};

const renderActivityIcon = (plan, actIndex, dayIndex) => {
  const type = getActivityType(plan, actIndex, dayIndex);
  return getActivitySvg(type);
};

// const getComments = (pics) => {
//   const comments = [];
//   if (pics && pics.length) {
//     pics.forEach((pic) => {
//       if (pic.comments) {
//         comments.push(pic.comments);
//       }
//     });
//   }
//   return comments.join(" ");
// };

const PlanDetailsPure = (props) => {
  const {
    trip: { tripdata, data },
    handleClickUpload,
    checkboxes,
    handleCheckboxChange,
    handleActivityNote,
    isOpen,
    photoIndex,
    images,
    setImages,
    setIndex,
    setIsOpen,
    deletePhoto,
    type,
    duration,
    fetchTrip,
    getNearbyPlaces,
    spEvents,
    auth: {
      userData,
      // userData: { currency },
    },
    // values,
  } = props;

  // console.log("POS 1", data);

  console.log("fetchTrip:", data);

  const [isImportDialogOpen, setImportDialog] = React.useState(false);
  const [importData, setImportData] = React.useState();

  const drivingTime = getDurationExplorer(data, duration);

  const closeImportDialog = () => {
    setImportDialog(false);
    setImportData(undefined);
  };

  const renderCheckboxAndUpload = (activity, day, index, activityData) => {
    if (!type) {
      let splitDot;
      if (activity.docpic) {
        splitDot = activity.docpic;
      }

      return (
        <>
          {splitDot && (
            <StyledLink
              href={activity.docpic}
              rel="noopener noreferrer"
              target="_blank"
            >
              <DocumentIcon
                style={{
                  width: "22px",
                  height: "18px",
                }}
              />
            </StyledLink>
          )}

          <StyledChecbox
            checked={checkboxes[activity.activity_id]}
            value={String(activity.activity_id)}
            onChange={() =>
              handleCheckboxChange(String(activity.activity_id), activity, day)
            }
          />
          <UploadPhotos
            src={AddImage}
            alt="upload"
            onClick={() => handleClickUpload(day, activity, index)}
          />
          <CustomIconButton
            disableRipple
            onClick={() => getNearbyPlaces(activity, "restaurant")}
          >
            <RestaurantIcon className="icon-style" />
          </CustomIconButton>
          <CustomIconButton
            disableRipple
            onClick={() => getNearbyPlaces(activity, "hospital")}
          >
            <LocalHospitalIcon className="icon-style" />
          </CustomIconButton>
          <CustomIconButton
            disableRipple
            onClick={() => getNearbyPlaces(activity, "gas_station")}
          >
            <LocalGasStationIcon className="icon-style" />
          </CustomIconButton>
          <CustomIconButton
            disableRipple
            onClick={() => {
              window.open(
                `https://www.google.com/maps/dir/${encodeURIComponent(
                  `${activityData.length > 1 && index !== 0
                    ? activityData[index - 1].place_from
                    : ""
                  }`
                )}/${encodeURIComponent(activity.place_from)}/@${activity.lat
                },${activity.long}`
              );
            }}
          >
            <NavigationIcon className="icon-style" />
          </CustomIconButton>
        </>
      );
    }
    if (type === "following" || type === "community") {
      return renderImportIcon(activity);
    }

    return null;
  };

  const renderImportIcon = (activity) => {
    if (type === "following" || type === "community") {
      return (
        <span
          className="import-trip"
          style={{ cursor: "pointer", marginLeft: "12px" }}
        >
          <ImportIcon
            style={{
              width: "20px",
              height: "20px",
            }}
            onClick={() => {
              setImportData(getImportActivity(activity));
              setImportDialog(true);
            }}
          />
        </span>
      );
    }
    return null;
  };

  if (data && data.length > 0) {
    return (
      <div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].photo}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() => setIndex((photoIndex + 1) % images.length)}
            reactModalStyle={{
              overlay: { zIndex: 1200 },
            }}
          />
        )}
        {data.map((day, dayIndex) => {
          return (
            <Container key={dayIndex}>
              <DayHeadingContainer>
                <DayPointer color={DayColors[dayIndex % DayColors.length]}>
                  <Typography variant="subtitle1">
                    Day {dayIndex + 1}
                  </Typography>
                  <Typography variant="body2">
                    {moment(tripdata.from_dt)
                      .add(dayIndex, "day")
                      .format("ddd Do")}
                  </Typography>
                </DayPointer>
                <Typography gutterBottom>{day.place}</Typography>
              </DayHeadingContainer>
              <div className="activity-container">
                {day.activitydata.map((activity, index, activityData) => {
                  const spEvent = spEvents.find(
                    (sp) => sp.event_id == activity.event
                  );
                  return (
                    <ActivityContainer key={index}>
                      <Grid container className="activity-content">
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems="center"
                          className="pointer-and-icons"
                        >
                          <ActivityPointerCotainer item>
                            {index === 0 && (
                              <div className="day-time-container">
                                {drivingTime[`day-${dayIndex}`][
                                  `day-${dayIndex}`
                                ] && (
                                    <DrivingTimeContainer>
                                      <img src={GreenRoadIcon} alt="driving" />
                                      <DrivingTime>
                                        {
                                          drivingTime[`day-${dayIndex}`][
                                          `day-${dayIndex}`
                                          ]
                                        }
                                      </DrivingTime>
                                    </DrivingTimeContainer>
                                  )}
                              </div>
                            )}

                            <ActivityPointer
                              color={
                                ActivityColors[dayIndex % ActivityColors.length]
                              }
                            >
                              <div>
                                <Typography variant="subtitle1">
                                  {`${dayIndex + 1}`}
                                  {(index + 10).toString(36)}
                                </Typography>
                              </div>
                              <div className="icon-div">
                                {renderActivityIcon(data, index, dayIndex)}
                              </div>
                            </ActivityPointer>
                          </ActivityPointerCotainer>
                          <Grid item sm={type ? 7 : 5} xs={7}>
                            <Tooltip
                              title={
                                activity.category === "meal"
                                  ? getActivityName(
                                    activity.place_from,
                                    activity.type
                                  )
                                  : activity.place_from
                              }
                            >
                              <ActivityName>
                                {activity.category === "meal"
                                  ? getActivityName(
                                    activity.place_from,
                                    activity.type
                                  )
                                  : activity.type === "specialEvents"
                                    ? getActivityName(
                                      activity.place_from,
                                      activity.type,
                                      spEvent.label
                                    )
                                    : `${activity.place_from}  ${activity.place_to.trim()
                                      ? `- ${getActivityName(
                                        activity.place_to
                                      )}`
                                      : ""
                                    }`}
                              </ActivityName>
                            </Tooltip>
                            <br />
                            {activity.type == "specialEvents" ? (
                              <Typography>{`${spEvent.discription}`}</Typography>
                            ) : null}
                            {/* {activity.trippic &&fd
                              activity.trippic.length > 0 && <Divider />}
                            {activity.trippic && activity.trippic.length > 0 && (
                              <Typography
                                className="comments"
                                variant="body2"
                                gutterBottom
                              >
                                {getComments(activity.trippic)}
                              </Typography>
                            )} */}
                          </Grid>
                          <Hidden xsDown>
                            <Grid container item xs justify="flex-end">
                              {renderCheckboxAndUpload(
                                activity,
                                day,
                                index,
                                day.activitydata
                              )}
                            </Grid>
                          </Hidden>
                        </Grid>
                        {/* START */}
                        <Grid
                          container
                          alignItems="center"
                          className="activity-container"
                        >
                          <Grid item md={2}>
                            <Typography
                              style={{ padding: "0px 10px" }}
                              variant="body2"
                            >
                              {userData?.currency
                                ? `${userData?.currency} `
                                : "INR"}
                              {activity.activity_cost
                                ? type
                                  ? activity.activity_local_cost
                                  : activity.activity_cost
                                : "0"}
                            </Typography>
                          </Grid>
                          {!type ? (
                            <Notes
                              activity={activity}
                              day={day}
                              handleActivityNote={handleActivityNote}
                            ></Notes>
                          ) : (
                            activity.note
                          )}
                        </Grid>
                        {/* eND */}
                        {
                          activity.type === "specialEvents" ?
                            spEvent?.img?.map((photo, index) => {
                              return (
                                <div className="grid-card" key={index}>
                                  <img
                                    alt={spEvent.label}
                                    src={photo}
                                    style={{
                                      padding: "0",
                                      minHeight: "90px",
                                      maxHeight: "90px",
                                      margin: "5px"
                                    }}
                                  />
                                </div>
                              );
                            }
                            ) : null
                        }
                        {activity.trippic.length > 0 && (
                          <Grid item xs={12}>
                            <PhotosGrid
                              setImages={setImages}
                              setIndex={setIndex}
                              setIsOpen={setIsOpen}
                              photos={activity.trippic}
                              deletePhoto={(id) => deletePhoto(id)}
                              type={type}
                              activity={activity}
                              fetchTrip={fetchTrip}
                            />
                          </Grid>
                        )}
                        <Hidden smUp>
                          <Grid container item xs>
                            {renderCheckboxAndUpload(
                              activity,
                              day,
                              index,
                              day.activitydata
                            )}
                          </Grid>
                        </Hidden>
                      </Grid>
                      <div
                        style={{ position: "relative", left: "10px" }}
                        className="activity-time-container"
                      >
                        {drivingTime[`day-${dayIndex}`][
                          `activity-${index}`
                        ] && (
                            <DrivingTimeContainer>
                              <img src={GreenRoadIcon} alt="driving" />
                              <DrivingTime>
                                {
                                  drivingTime[`day-${dayIndex}`][
                                  `activity-${index}`
                                  ]
                                }
                              </DrivingTime>
                            </DrivingTimeContainer>
                          )}
                      </div>
                    </ActivityContainer>
                  );
                })}
              </div>
            </Container>
          );
        })}
        <ImportTripDialog
          isOpen={isImportDialogOpen}
          handleClose={closeImportDialog}
          tripToImport={importData}
          isPhoto={true}
        />
      </div>
    );
  }

  if (type === "following" || type === "community") {
    return <NoContent heading="Nothing to show here" alignText="center" />;
  }

  return (
    <NoContent
      heading="It seems you haven't started planning yet"
      alignText="center"
      to={`/trip-plan/edit/${tripdata.trip_id}`}
      buttonText="Start Planning"
    />
  );
};

export default PlanDetailsPure;
