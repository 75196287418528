import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Link } from 'react-router-dom'
import {
  StyledAppBar,
  OtherHeadings,
  HeadingContainer,
  StyledToolBar,
  LinksContainer,
} from './styles'
import AvatarYeteh from './Avatar'
import Logo from '../../assets/logo.png'
//import { HashLink } from "react-router-hash-link-offset";
import { HashLink } from 'react-router-hash-link'
import NotificationDrawer from './NotificationDrawer'
import { Button } from '@material-ui/core'
import { OnboardingContext } from '../../Onboarding/OnboardingProvider'
import { ReactComponent as HomeIcon } from '../../assets/profile/home.svg'
import { ReactComponent as AddUser } from '../../assets/profile/add-user.svg'
import IconButton from '@material-ui/core/IconButton'

const AppBarPure = ({
  isLoggedIn,
  anchorEl,
  handleClick,
  handleClose,
  logout,
  auth,
}) => {
  const open = Boolean(anchorEl)

  const onboardingContext = React.useContext(OnboardingContext)

  console.log(auth)

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <StyledAppBar position="fixed">
      <StyledToolBar>
        <Grid
          container
          wrap="nowrap"
          justify="space-between"
          alignItems="center"
        >
          <HeadingContainer item>
            <Link to={'/'}>
              <img
                style={{ height: '60px', padding: '5px' }}
                alt="yeteh"
                src={Logo}
              />
            </Link>
          </HeadingContainer>
          <LinksContainer
            item
            container
            spacing={2}
            wrap="nowrap"
            alignItems="center"
            justify="flex-end"
          >
            {isLoggedIn && (
              <Grid item>
                <OtherHeadings component={Link} to="/">
                  <HomeIcon />
                </OtherHeadings>
              </Grid>
            )}
            {isLoggedIn && auth?.userData?.role_id === 3 && (
              <Grid item>
                <IconButton
                  style={{ padding: 0 }}
                  color="inherit"
                  onClick={() => onboardingContext.setAddUserOpen(true)}
                >
                  <AddUser />
                </IconButton>
              </Grid>
            )}
            {
              <Hidden xsDown>
                {!isLoggedIn && (
                  <Grid item>
                    <OtherHeadings
                      component={HashLink}
                      scroll={(el) => scrollWithOffset(el, 80)}
                      to="#how-it-works"
                    >
                      How it works?
                    </OtherHeadings>
                  </Grid>
                )}
              </Hidden>
            }
            {!isLoggedIn && (
              <Button
                onClick={() => onboardingContext.setSignupOpen(true)}
                color="secondary"
                variant="contained"
              >
                SIGN UP
              </Button>
            )}
            {isLoggedIn && (
              <Grid item>
                <NotificationDrawer />
              </Grid>
            )}
            {isLoggedIn && (
              <Grid item style={{ padding: '0px' }}>
                <AvatarYeteh
                  auth={auth}
                  handleClick={handleClick}
                  handleClose={handleClose}
                  logout={logout}
                  open={open}
                  anchorEl={anchorEl}
                  isLoggedIn={isLoggedIn}
                />
              </Grid>
            )}
          </LinksContainer>
        </Grid>
      </StyledToolBar>
    </StyledAppBar>
  )
}

export default AppBarPure
