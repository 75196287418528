import React, { useState, useRef, useEffect } from "react";
import {
  DayPointer,
  DayHeadingContainer,
  DayFieldGrid,
  DayDeleteIconGrid,
  AddActivityGrid,
  ActivityTextScreen,
  ActivityTextSmallScreen,
  AddDayButton,
} from "./styles";
import {
  required,
  requiredAddress,
} from "../../../common/FormFields/validations";
import { Field, FieldArray } from "redux-form";
import GoogleAutocomplete from "../../../common/FormFields/googlePlaceAutocomplete";
import ActivityFieldArray from "./ActivityFieldArray";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import { ReactComponent as DeleteIcon } from "../../../assets/planner/delete.svg";
import { ReactComponent as PlusIcon } from "../../../assets/planner/add.svg";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { DayColors } from "../../../common/utils";
import moment from "moment";
import { Grid, Typography } from "@material-ui/core";
import ReduxFormTextField from "../../../common/FormFields/TextField";
import { ADD_MEAL } from "../../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import { GridContainer } from "../../styles";
import { ReactComponent as GreenRoadIcon } from "../../../assets/food.svg";

const DayFieldArray = (props) => {
  const {
    formValues,
    fields,
    lat,
    lng,
    id,
    addActivity,
    addMeal,
    addMealtoActivity,
    handleRecomendationClick,
    classes,
    ref,
    forwardedRef,
    trip: { tripdata, spEvents },
    drivingTime,
    token,
    dayRef,
    showSnackBar,
    auth,
    updateEventLocation
  } = props;

  const tripAccess = tripdata.trip_access_type;
  
  const handleClickScroll = (index) => {
    const element = document.getElementsByClassName(`activityMeal-${index}`);
    if (element) {
      setTimeout(()=>{
        element[element.length - 1].scrollIntoView({ behavior: 'smooth'});
      },500)
    }
  }; 
  
  const styles = {
    btn: {
      border: "1px solid #fff",
      color: "#fff",
      backgroundColor: "transparent",
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      marginRight: "10px",
    },
    btnActive: {
      border: "1px solid #4fc099",
      color: "#fff",
      backgroundColor: "#4fc099",
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      marginRight: "10px",
    },
    btnExtra: {
      border: "1px solid #F26724",
      color: "#fff",
      backgroundColor: "#F26724",
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      marginRight: "10px",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    img: {
      marginTop: "25px",
      marginRight: "15px",
      height: "20px",
      width: "20px",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
    btnScroller:{
      backgroundColor:'transparent',
      border:0,
      padding:0,
      color:'#08E8DE',
      fontWeight:'600'
    }
  };

  return (
    <>
      <Droppable type="days" droppableId="day">
        {/* {console.log(dayRef)} */}
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {/* <Tabs>
              {fields.map((day, index) => {
                return <Tab label={`Day ${index + 1}`} />;
              })}
            </Tabs> */}

            {fields.map((day, index) => (
              <Draggable key={index} draggableId={`day-${index}`} index={index}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <div
                      style={{ padding: "8px" }}
                      ref={(el) => (dayRef.current[index] = el)}
                    >
                      <DayHeadingContainer container spacing={2} wrap="nowrap">
                        <DayPointer
                          item
                          xs="auto"
                          color={DayColors[index % DayColors.length]}
                          {...provided.dragHandleProps}
                        >
                          <Typography variant="subtitle1">
                            Day {index + 1}
                          </Typography>
                          <Typography variant="body2">
                            {moment(tripdata.from_dt)
                              .add(index, "day")
                              .format("ddd Do")}
                          </Typography>
                        </DayPointer>
                        <DayFieldGrid item xs>
                          <Field
                            label="Starting Place"
                            name={`${day}.startingplace`}
                            placeholder="Starting Place"
                            component={GoogleAutocomplete}
                            onSelect={(res) => {
                              return handleRecomendationClick(res, "Day");
                            }}
                            position="absolute"
                            validate={requiredAddress}
                            lat={+lat}
                            lng={+lng}
                          />
                        </DayFieldGrid>
                        <DayDeleteIconGrid item xs="auto">
                          <IconButton onClick={() => fields.remove(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </DayDeleteIconGrid>
                        <div>
                          <AddActivityGrid item xs="auto">
                            <Button
                              className="add-activity-btn"
                              onClick={() => {addActivity(`${day}.activities`);handleClickScroll(index)}}
                              color="secondary"
                              variant="outlined"
                            >
                              <PlusIcon />
                              <ActivityTextScreen>ACTIVITY</ActivityTextScreen>
                              <ActivityTextSmallScreen>
                                Act
                              </ActivityTextSmallScreen>
                            </Button>
                          </AddActivityGrid>
                          <br />
                          <AddActivityGrid item xs="auto">
                            <Button
                              className="add-activity-btn"
                              color="secondary"
                              variant="outlined"
                              onClick={() =>{addMeal(`${day}.activities`);handleClickScroll(index) }}
                            >
                              <PlusIcon />
                              <ActivityTextScreen>MEAL</ActivityTextScreen>
                            </Button>
                          </AddActivityGrid>
                        </div>
                      </DayHeadingContainer>
                    </div>
                    <Droppable
                      type="activity"
                      droppableId={`day-${index}-activities`}
                    
                    >
                      {(provided, snapshot) => (
                        <div
                        
                          ref={provided.innerRef}
                          style={{
                            minHeight: "70px",
                            borderLeft: "1px solid rgba(255, 255, 255, 0.37)",
                            paddingBottom: "10px",
                          }}
                          {...provided.droppableProps}
                        >
                           <FieldArray
                           
                            classes={classes}
                            formValues={formValues}
                            lat={+lat}
                            lng={+lng}
                            name={`${day}.activities`}
                            daykey={day}
                            handleActivityNote={props.handleActivityNote}
                            day={props.trip.data[index]}
                            dayIndex={index}
                            trip={props.trip}
                            spEvents={spEvents}
                            auth={auth}
                            id={id}
                            
                            addMealtoActivity={addMealtoActivity}
                            updateEventLocation={updateEventLocation}
                            handleRecomendationClick={handleRecomendationClick}
                            component={ActivityFieldArray}
                            tripAccess={tripAccess}
                            dayAccess={fields.get(index).type}
                            drivingTime={drivingTime}
                            daysLen={fields.length}
                            token={token}
                            showSnackBar={showSnackBar}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <AddDayButton
        color="secondary"
        onClick={() => fields.push({})}
        variant="outlined"
      >
        <PlusIcon />
        Day
      </AddDayButton>
    </>
  );
};

export default DayFieldArray;
