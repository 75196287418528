import React, { useState } from "react";
import { StyledDialog, PlanContainer } from "./styles";
import PlanHeading from "./PlanHeading";
import PlanForm from "./PlanForm";
import { getDuration } from "../../common/utils";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LoadingScreen from "../../common/LoadingScreen";

const PlanPure = (props) => {
  const {
    handleSubmit,
    submitting,
    trip,
    trip: {
      tripdata: { lat, longi },
    },
    setDialogOpen,
    addActivity,
    handleRecomendationClick,
    values,
    action,
    duration,
    auth: {
      userData: { currency },
    },
    importChips,
    removeImportChip,
    showSnackBar,
  } = props;

  const [loading, setLoading] = useState(false);
  console.log("loading:", loading);

  const makePDF = () => {
    html2canvas(document.querySelector("#capture"), {
      logging: true,
      letterRendering: 1,
      useCORS: true,
    }).then((canvas) => {
      // document.body.appendChild(canvas);
      const componentWidth = document.querySelector("#capture").offsetWidth;
      const componentHeight = document.querySelector("#capture").offsetHeight;

      const orientation = componentWidth >= componentHeight ? "l" : "p";
      const img = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation,
        unit: "px",
      });
      // doc.text("Html to PDF", 10, 10);
      doc.internal.pageSize.width = componentWidth;
      doc.internal.pageSize.height = componentHeight;
      doc.addImage(img, "PNG", 0, 0, componentWidth, componentHeight);
      doc.save(`${trip?.tripdata?.name}-${trip?.tripdata?.user_name}.pdf`);
      setLoading(false);
    });
  };

  return (
    <>
      <PlanContainer>
        <form
          onSubmit={handleSubmit}
          id="capture"
          style={{ backgroundColor: "#2E2E2E" }}
        >
          <PlanHeading
            action={action}
            trip={trip}
            formValues={values}
            currency={currency}
            submitting={submitting}
            setDialogOpen={setDialogOpen}
            savePDF={makePDF}
            setLoading={setLoading}
          />

          <PlanForm
            formValues={values}
            handleRecomendationClick={handleRecomendationClick}
            addActivity={addActivity}
            lat={+lat}
            lng={+longi}
            trip={trip}
            drivingTime={getDuration(values, duration)}
            importChips={importChips}
            removeImportChip={removeImportChip}
            showSnackBar={showSnackBar}
          />
        </form>
      </PlanContainer>
      <StyledDialog
        fullWidth={true}
        maxWidth="sm"
        fullScreen={loading}
        open={loading}
      >
        <LoadingScreen />
      </StyledDialog>
    </>
  );
};

export default PlanPure;
