import React from "react";
import PhotoField from "./photoField";
import NoPic from "../../../assets/no-profile-pic.png";
import { StyledImg } from "../../styles";

const ProfileCardPure = (props) => {
  return (
    <PhotoField accept="image/*" name="prof_pic">
      <StyledImg
        alt="user-profile"
        src={
          props.values && props.values.prof_pic
            ? props.values.prof_pic.data?.url
            : props.auth.userData.profile_pic?.url
            ? props.auth.userData.profile_pic?.url
            : NoPic
        }
      />
    </PhotoField>
  );
};

export default ProfileCardPure;
