import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";

export const StyledDialog = styled(({ ...other }) => (
  <Dialog
    {...other}
    classes={{
      paper: "paperContainer",
    }}
    BackdropProps={{ classes: { root: "import-dialog-bg" } }}
  />
))`
  .dialog-title {
    background: ${(props) => props.theme.primaryBlack};

    .heading {
      font-weight: 600;
    }
  }

  .btn-creator {
    padding: 16px 24px 0 24px;
    display: flex;
    align-items: center;

    > span {
      margin-right: 5px;
    }
  }
  && .paperContainer {
    background: ${(props) => props.theme.appBar};
    border-radius: 15px;
    .import-new-root {
      padding: 0;
      opacity: 1 !important;
      color: ${(props) => props.theme.lightGreen} !important;
      text-transform: none;
      justify-content: flex-start;
      font-size: 1rem;
      font-weight: 400;
      .import-new {
        justify-content: flex-start;
        svg {
          margin-right: 10px;
        }
      }
    }

    .list-container {
      padding: 0px 24px 24px 24px;
      margin-top: 15px;
      max-height: 300px;
      overflow-y: auto;
      ${(props) => props.theme.breakpoints.down("sm")} {
        max-height: 100%;
      }
    }

    .existing-heading {
      margin-left: 40px;
    }
  }
`;

export const PlanContainer = styled(Grid)`
  position: relative;
  height: 100%;
  .plan-heading {
    border-bottom: 1px solid #777777;
    padding: 5px 20px;
    .privacy-type-container {
      display: flex;
    }
    .privacy-type {
      font-weight: 700;
      white-space: nowrap;
    }

    .plan-date {
      margin-right: 10px;
      white-space: nowrap;
    }
  }
  .plan-form {
    padding: 20px;
  }

  .save-plan-btn {
    // background: ${(props) => props.theme.paleGreen};
  }

  .save-plan-btn:hover {
    // background: ${(props) => props.theme.green};
  }

  .plan-title-container {
    @media (max-width: 1024px) {
      max-width: 76%;
    }
  }
`;

export const StyledFab = styled(Fab)`
  && {
    position: fixed;
    right: 5px;
    bottom: 50px;
    z-index: 1;
  }
`;
export const PDFFab = styled(Fab)`
  && {
    width: 46px;
    height: 46px;
    border-radius: 30%;
    margin-top: 5px;
  }
  ,
  && img {
    margin-right: -3px;
  }
`;

export const CurrencyContainer = styled.span`
  border-radius: 50px;
  background-color: #4fc099;
  padding: 3px 7px;
  font-size: 10px;
  font-weight: 500;
  color: #000;
  margin-top: -505px;
`;

export const FormHeaderContainer = styled.div`
  // position: sticky;
  // top: 0px;
  // left 20px;
  // z-index :100;
`;
