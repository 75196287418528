import CommunityIcon from "./assets/community.svg";
import PrivateIcon from "./assets/private.svg";
import FriendsIcon from "./assets/friends.svg";

export const GENERIC_ERROR_MESSAGE = "Something went wrong !";

export const API_ERROR = "Some error occured";

export const PAGE_NOT_FOUND = "Page Not Found !";

export const PAGE_NOT_FOUND_DESC =
  "Oops! The page you are looking for doesn't exist";

export const PROFILE_UPDATED_SUCCESS = "Profile updated";

export const MY_ID = 3;

export const FRIENDS_ID = 2;

export const COMMUNITY_ID = 1;

export const ACTIVITY_TYPES = [
  {
    value: "specialEvents",
    label: "Events at Jashan"
  },
  {
    value: "restaurant",
    label: "Restaurant"
  },
  {
    value: "event",
    label: "Custom Activity"
  },
  {
    value: "places",
    label: "Places"
  },
  {
    value: "hotel",
    label: "Hotel"
  },
  {
    value: "rail",
    label: "Rail"
  },
  {
    value: "flight",
    label: "Flight"
  }
];
export const MEAL_TYPES = [
  {
    label : "breakfast",
    value:"Breakfast"
  },
  {
    label : "lunch",
    value:"Lunch"
  },
  {
    label:"evening snacks",
    value:"EveningSnacks"
  },
  {
    label:"dinner",
    value:"Dinner"
  }

]


export const FOLLOWING = "Following";

export const FOLLOW = "Follow";

export const REQUESTED = "Requested";

export const NO_CONTENT_PEOPLE = "Start following people";

export const NO_CONTENT_PHOTOS = "No photos";

export const NO_CONTENT_FOLLOWERS = "You don't have any followers";

export const NO_CONTENT_COMMUNITY = "Let's create everlasting travel memories";

export const NO_CONTENT_PERSONAL_PHOTOS = "No photos. Take a break and travel!";

export const UPDATE_PRIVACY_SUCCESS = "Privacy settings updated!";

export const PHOTO_UPLOAD_SUCCESS = "Photos uploaded";

export const PRIVACY_OPTIONS = [
  {
    label: "Public [ To Community]",
    value: String(COMMUNITY_ID),
    icon: CommunityIcon
  },
  {
    label: "Public [ To Followers ]",
    value: String(FRIENDS_ID),
    icon: FriendsIcon
  },
  {
    label: "Private [ Default ]",
    value: String(MY_ID),
    icon: PrivateIcon
  }
];

export const ADD_MEAL = [
  {
    label : "Breakfast (any two)",
    value:"breakfast",
    sublabel: "Tea, coffee, bread butter jam"
  },
  {
    label : "Lunch (any eight)",
    value:"lunch",
    sublabel: "Salad, Papad, Aachar , Chaas, Roti(phulka)"
  },
  {
    label:"Evening Snacks (any one)",
    value:"eveningSnacks",
    sublabel: "Tea, coffee, biscuits"
  },
  {
    label:"Dinner (any eight)",
    value:"dinner",
    sublabel: "We can either pick complete fast food menu or we could do a proper meal. A third option is to do a mix of bother. I.E pick 2 choices from fast food menu and 2 choices from full meal menu"
  }

]


export const RECOMMENDATION_HELP_TEXT =
  "Recommends hotels/restaurants/places/ events based on type selected and your previous location ";
