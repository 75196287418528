import { store } from "./App";
import axios from "axios";
import { enqueueSnackbar } from "./Master/Notifier/duck";

//  "http://3.6.108.152:4300";
//  api.yeteh.com

export const BaseUrl =
  process.env.NODE_ENV === "production"
    ? "https://yeteh-stage-api.ekahalwebsite.com/"
    : "https://yeteh-stage-api.ekahalwebsite.com/";

export const axiosInst = axios.create({
  baseURL: BaseUrl,
});

export const axiosConfig = (token, module) => {
  return {
    headers: {
      "access-token": token,
      module,
    },
  };
};

axiosInst.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.message === "Network Error") {
      store.dispatch(
        enqueueSnackbar({
          message: "Make sure you have an active internet connection!",
          variant: "error",
        })
      );
      return Promise.reject({
        response: {
          data: "Network Error",
        },
      });
    } else {
      if (error.response.status === 401) {
        store.dispatch({
          type: "LOGOUT_REQUEST",
        });
      }
      return Promise.reject(error);
    }
  }
);
