import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";

export const StyledDialog = styled(({ ...other }) => (
  <Dialog
    {...other}
    classes={{
      paper: "paperContainer",
    }}
    BackdropProps={{ classes: { root: "import-dialog-bg" } }}
  />
))`
  .dialog-title {
    background: ${(props) => props.theme.primaryBlack};

    .heading {
      font-weight: 600;
    }
  }

  .btn-creator {
    padding: 16px 24px 0 24px;
    display: flex;
    align-items: center;

    > span {
      margin-right: 5px;
    }
  }
  && .paperContainer {
    background: ${(props) => props.theme.appBar};
    border-radius: 15px;
    .import-new-root {
      padding: 0;
      opacity: 1 !important;
      color: ${(props) => props.theme.lightGreen} !important;
      text-transform: none;
      justify-content: flex-start;
      font-size: 1rem;
      font-weight: 400;
      .import-new {
        justify-content: flex-start;
        svg {
          margin-right: 10px;
        }
      }
    }

    .list-container {
      padding: 0px 24px 24px 24px;
      margin-top: 15px;
      max-height: 300px;
      overflow-y: auto;
      ${(props) => props.theme.breakpoints.down("sm")} {
        max-height: 100%;
      }
    }

    .existing-heading {
      margin-left: 40px;
    }
  }
`;

export const PlanContainer = styled(Grid)`
  height: 100%;
  .plan-heading-container {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.37);
    padding: 20px 20px 5px 20px;
  }
`;

export const StyledTripName = styled(Typography)`
  && {
    font-family: Poppins, sans-serif;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const StyledPrivacyHeading = styled(Typography)`
  && {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 0.0025em;
  }
`;

export const StyledUsername = styled(Typography)`
  && {
    font-family: Open Sans;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.0025em;
    margin-right: 10%;
  }
`;

export const UsernamePrivacyContainer = styled.div`
  display: flex;
  align-items: center;
  .date-privacy {
    display: flex;
    width: auto;
    justify-content: space-between;
    padding-right: 10px;

    hr {
      background: ${(props) => props.theme.primaryColor};
    }

    p:first-child {
      margin-left: 0;
    }

    hr,
    p {
      margin: 0 10px;
    }
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    ${(props) => props.theme.breakpoints.down("sm")} {
      margin-top: 0.4rem;
    }
  }
  .edit-trip {
    border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
    color: ${(props) => `${props.theme.lightGreen}`};
  }
  .edit-trip:hover {
    border: ${(props) => `1px solid  ${props.theme.lightGreen}`};
  }
`;

export const ActionImg = styled.img`
  margin-right: 6px;
`;

export const TotalAmoutSpan = styled.span`
  border-radius: 50px;
  background-color: #4fc099;
  padding: 3px 7px;
  fontsize: 10px;
`;
