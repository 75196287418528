import React from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { StyledAside, RouteContainer, RightPane, BottomTabs } from "./styles";
import ProfileCard from "./ProfileCard";
import WorldMap from "../WorldMap";
import { TRIPS_URL, PHOTOS_URL, PEOPLE_URL, PROFILE_EDIT_URL } from "../urls";
import AuthRoute from "../Routes/AuthRoute";
import { ReactComponent as TripsIcon } from "../assets/profile/trips.svg";
import { ReactComponent as PhotosIcon } from "../assets/profile/photos.svg";
import { ReactComponent as PeopleIcon } from "../assets/profile/people.svg";
import { ReactComponent as SettingsIcon } from "../assets/profile/settings.svg";
import Trips from "./Trips";
import Photos from "./Photos";
import People from "./People";
import EditProfile from "./EditProfile";
import NotFound from "../common/NotFound";
import { PAGE_NOT_FOUND, PAGE_NOT_FOUND_DESC } from "../constants";
import { connect } from "react-redux";
import { getProfile } from "./duck";

const NAV_LINKS = [
  {
    label: "Trips",
    to: TRIPS_URL,
    icon: () => <TripsIcon />,
    root: true,
  },
  {
    label: "Photos",
    to: PHOTOS_URL,
    icon: () => <PhotosIcon />,
  },
  {
    label: "People",
    to: PEOPLE_URL,
    icon: () => <PeopleIcon />,
  },
  {
    label: "Settings",
    to: PROFILE_EDIT_URL,
    icon: () => <SettingsIcon />,
  },
];

const NAV_LINKS_USER = (userId) => [
  {
    label: "Trips",
    to: `/user/${userId}/trips`,
    icon: () => <TripsIcon />,
    root: true,
  },
  {
    label: "Photos",
    to: `/user/${userId}/photos`,
    icon: () => <PhotosIcon />,
  },

  // Removed settings tab from other users profile since check-in and other fields were not getting updated. Edited by Parminder
  /* {
    label: "Settings",
    to: PROFILE_EDIT_URL,
    icon: () => <SettingsIcon />,
  },*/
];

const ProfilePure = (props) => {
  const {
    match: {
      url,
      params: { id },
    },
    location: { pathname },
    getUserProfile,
  } = props;

  console.log("profileprops:", props.match);

  React.useEffect(() => {
    if (getUserProfile) {
      getUserProfile();
    }
  }, [getUserProfile]);

  // if (url.includes("/user") && isNaN(parseInt(id, 10))) {
  //   return (
  //     <NotFound errorMsg={PAGE_NOT_FOUND} description={PAGE_NOT_FOUND_DESC} />
  //   );
  // }

  if (url.includes("/user")) {
    return (
      <>
        <StyledAside>
          <ProfileCard userId={id} />
          <div className="profile-tabs">
            {NAV_LINKS_USER(id).map((link) => (
              <Button
                key={link.to}
                disableRipple
                component={NavLink}
                to={link.to}
                activeClassName="selected"
                className={
                  pathname === link.to ||
                  (link.root &&
                    !pathname.includes("/trips") &&
                    !pathname.includes("/photos"))
                    ? "selected"
                    : "nav-btn"
                }
              >
                <span className="selected-border" />
                {link.icon()}
                <span>{link.label}</span>
              </Button>
            ))}
          </div>
        </StyledAside>
        <RightPane>
          {!pathname.includes("settings") && <WorldMap userId={+id} />}
          <ProfileCard userId={+id} />
          <RouteContainer>
            <Switch>
              {/* <AuthRoute
                exact
                path={[`${url}/trips`, url]}
                component={(props) => <Trips {...props} userId={id} />}
              /> */}
              <Route
                exact
                path={[`${url}/trips`, url]}
                render={(props) => <Trips {...props} userId={id} />}
              />
              {/* <AuthRoute
                exact
                path={[`${url}/photos`]}
                userId={id}
                component={(props) => <Photos {...props} userId={id} />}
              /> */}
              <Route
                exact
                path={[`${url}/photos`]}
                // userId={id}
                render={(props) => <Photos {...props} userId={id} />}
              />
              {/* <AuthRoute
                exact
                path={[`${url}/settings`]}
                component={(props) => <EditProfile {...props} userId={+id} />}
              /> */}
              <Route
                render={() => (
                  <NotFound
                    errorMsg={PAGE_NOT_FOUND}
                    description={PAGE_NOT_FOUND_DESC}
                  />
                )}
              />
            </Switch>
          </RouteContainer>
        </RightPane>
        <BottomTabs>
          {NAV_LINKS_USER(id).map((link) => (
            <Button
              key={link.to}
              disableRipple
              component={NavLink}
              to={link.to}
              activeClassName="selected"
              className={pathname === url && link.root ? "selected" : "nav-btn"}
            >
              <span className="selected-border" />
              {link.icon()}
              <span>{link.label}</span>
            </Button>
          ))}
        </BottomTabs>
      </>
    );
  }

  return (
    <>
      <StyledAside>
        <ProfileCard />
        <div className="profile-tabs">
          {NAV_LINKS.map((link) => (
            <Button
              key={link.to}
              disableRipple
              component={NavLink}
              to={link.to}
              activeClassName="selected"
              className={pathname === url && link.root ? "selected" : "nav-btn"}
            >
              <span className="selected-border" />
              {link.icon()}
              <span>{link.label}</span>
            </Button>
          ))}
        </div>
      </StyledAside>
      <RightPane>
        {!pathname.includes("settings") && <WorldMap />}
        <ProfileCard />
        <RouteContainer>
          <Switch>
            <AuthRoute path={[`${url}/trips`, url]} exact component={Trips} />
            <AuthRoute
              exact
              path={[`${url}/photos`]}
              component={(props) => <Photos {...props} />}
            />
            <AuthRoute
              exact
              path={[`${url}/people`]}
              component={(props) => <People {...props} />}
            />
            <AuthRoute
              exact
              path={[`${url}/settings`]}
              component={(props) => <EditProfile {...props} />}
            />
            <Route
              render={() => (
                <NotFound
                  errorMsg={PAGE_NOT_FOUND}
                  description={PAGE_NOT_FOUND_DESC}
                />
              )}
            />
          </Switch>
        </RouteContainer>
      </RightPane>
      <BottomTabs>
        {NAV_LINKS.map((link) => (
          <Button
            key={link.to}
            disableRipple
            component={NavLink}
            to={link.to}
            activeClassName="selected"
            className={pathname === url && link.root ? "selected" : "nav-btn"}
          >
            <span className="selected-border" />
            {link.icon()}
            <span>{link.label}</span>
          </Button>
        ))}
      </BottomTabs>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(getProfile()),
  };
};

export default connect(null, mapDispatchToProps)(ProfilePure);
