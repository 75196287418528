export const GET_TRIP_REQUEST = "GET_TRIP_REQUEST";
export const GET_TRIP_SUCCESS = "GET_TRIP_SUCCESS";
export const GET_TRIP_FAILURE = "GET_TRIP_FAILURE";

export const GET_IMPORT_TRIP_REQUEST = "GET_IMPORT_TRIP_REQUEST";
export const GET_IMPORT_TRIP_SUCCESS = "GET_IMPORT_TRIP_SUCCESS";
export const GET_IMPORT_TRIP_FAILURE = "GET_IMPORT_TRIP_FAILURE";

export const CREATETRIP_FAILURE = "CREATETRIP_FAILURE";
export const UPDATE_TRIP = "UPDATE_TRIP";
export const GET_COOWNER_PROFILE = "GET_COOWNER_PROFILE";
export const COOWNER_LIST = "COOWNER_LIST";
export const SPEVENT_LIST = "SPEVENT_LIST";
