import { axiosInst, axiosConfig } from "../../../axios";

export const getPhotosApi = (token, tripType, pageNo, name) => {
  return axiosInst.post(
    "/photos",
    { type: tripType, perpage: 10, pageno: pageNo, name },
    axiosConfig(token)
  );
};

export const getUserPhotosApi = (token, id, pageNo, name) => {
  if (token) {
    return axiosInst.post(
      "/othersphotos",
      { perpage: 10, pageno: pageNo, user_name: id, name },
      // { perpage: 10, pageno: pageNo, user_id: id, name },
      axiosConfig(token)
    );
  }
  return axiosInst.post("/othersphotos", {
    perpage: 10,
    pageno: pageNo,
    user_name: id,
    name,
  });
};

export const likePhotoApi = (token, photoId, status) => {
  return axiosInst.post(
    "/like",
    { photo_id: String(photoId), like: status ? 1 : 0 },
    axiosConfig(token)
  );
};

export const updatePrivacyApi = (token, photoId, type) => {
  return axiosInst.post(
    "/picaccess",
    { photo_id: String(photoId), type: String(type) },
    axiosConfig(token)
  );
};

export const uploadPhotoApi = (token, formData) => {
  return axiosInst.post("/photoupload", formData, axiosConfig(token));
};
