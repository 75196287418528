import React from "react";
import ReactDOM from "react-dom";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { StyledSelect } from "./style";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { InputAdornment } from "@material-ui/core";

class ReduxFormTextField extends React.Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.variant === "outlined") {
      this.labelNode = ReactDOM.findDOMNode(this.labelRef.current);
      this.forceUpdate();
    }
  }

  render() {
    const {
      input,
      label,
      placeholder,
      meta: { error, touched },
      className,
      FormHelperTextProps,
      helperText,
      id,
      nativeInputProps,
      InputProps,
      inputRef,
      required,
      type,
      variant,
      select,
      children,
      labelWidth,
      disabled,
      alignItems,
      padding,
      display,
      currency,
      ...other
    } = this.props;

    const InputMore = {};

    const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

    const InputElement = (
      <Input
        autoComplete={input.name}
        name={input.name}
        type={type}
        id={id}
        inputRef={inputRef}
        onChange={input.onChange}
        placeholder={placeholder}
        inputProps={nativeInputProps}
        fullWidth={true}
        disabled={disabled}
        multiline
        startAdornment={
          currency ? (
            <InputAdornment position="start">
              <p>{currency}</p>
            </InputAdornment>
          ) : undefined
        }
        // InputProps={}
        {...other}
        {...input}
        {...InputMore}
        {...InputProps}
      />
    );

    return (
      <FormControl
        aria-describedby={helperTextId}
        className={className}
        error={error && touched ? true : false}
        fullWidth={true}
        required={required}
        variant={variant}
        disabled={disabled}
      >
        {label && (
          <InputLabel htmlFor={id} ref={this.labelRef}>
            {label}
          </InputLabel>
        )}
        {select ? (
          <StyledSelect
            IconComponent={!disabled ? ArrowDropDownIcon : () => null}
            value={input.value}
            input={InputElement}
            disabled={disabled}
            padding={padding}
            display={display}
          >
            {children}
          </StyledSelect>
        ) : (
          InputElement
        )}
        {error && touched && (
          <FormHelperText
            error={error && touched ? true : false}
            id={helperTextId}
            {...FormHelperTextProps}
          >
            {error && touched ? error : helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default ReduxFormTextField;
