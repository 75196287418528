import { createTripApi, importTripApi } from "./duck/services";
import { enqueueSnackbar } from "../Master/Notifier/duck";

export const SubmitCreateTrip = (parentProps) => (values, dispatch, props) => {
	const {
		auth: { token },
		history,
	} = parentProps;

	const formData = {
		name: values.name,
		origin: values.origin.address,
		from_dt: values.from_dt,
		to_dt: values.to_dt,
		lat: values.origin.lat,
		longi: values.origin.lng,
		city: values.origin.city,
		type: values.type,
		coOwners:props.coowners
	};
	
	if (values.newTripId) {
		
		// we need to import the with the given id and create a new trip
		formData.trip_id = values.newTripId;
		formData.lat = values.lat;
		formData.longi = values.longi;
		formData.city = values.city;
		formData.origin = values.origin.address;

		// console.log(formData);

		return importTripApi(formData, token)
			.then((res) => {
				if (res.data.status === 200) {
					dispatch(
						enqueueSnackbar({
							message: "Trip imported",
							variant: "success",
						})
					);
					// navigate to the new trip once you get the id.
					history.push(`/trip-plan/edit/${res.data.trip_id}`);
				} else {
					dispatch(
						enqueueSnackbar({
							message: "Some error occured",
							variant: "error",
						})
					);
				}
			})
			.catch((err) => {
				if (err.response.data.status === 409) {
					dispatch(
						enqueueSnackbar({
							message: "Trip name already taken",
							variant: "error",
						})
					);
				} else {
					dispatch(
						enqueueSnackbar({
							message: "Some error occured",
							variant: "error",
						})
					);
				}
			});
	}

	return createTripApi(formData, token)
		.then((res) => {
			if (res.data.status === 200) {
				dispatch(
					enqueueSnackbar({
						message: "New Trip Created",
						variant: "success",
					})
				);

				history.push(`/trip-plan/edit/${res.data.data.trip_id}`);
			} else {
				dispatch(
					enqueueSnackbar({
						message: "Some error occured",
						variant: "error",
					})
				);
			}
		})
		.catch((err) => {
			if (err.response.data.status === 409) {
				dispatch(
					enqueueSnackbar({
						message: "Trip name already taken",
						variant: "error",
					})
				);
			} else {
				dispatch(
					enqueueSnackbar({
						message: err.response.data.message,
						variant: "error",
					})
				);
			}
		});
};
