import React from "react";
import Resizable from "re-resizable";
import Hidden from "@material-ui/core/Hidden";
import { parse } from "query-string";
import { GridContainer, GoogleSearchContainer } from "./styles";
import NewTourDialog from "./NewTourDialog";
import {
  createMarkers,
  getImportActivity,
  activityImportChip,
} from "../common/utils";
import GoogleSearch from "./GoogleSearch";
import { MapAndPlannerComponent } from "./MapAndPlannerComponent";
import { DesktopMap } from "./DesktopMap";
import { MobileMap } from "./MobileMap";
import { GENERIC_ERROR_MESSAGE, COMMUNITY_ID, FRIENDS_ID } from "../constants";
import NotFound from "../common/NotFound";
import { Redirect } from "react-router-dom";

const TourPlanPure = (props) => {
  const {
    match: {
      params: { action, importType, tripId, id },
    },
    history,
    history: {
      location: { search },
    },
    submitCreateTrip,
    submitEditTrip,
    tripValues,
    trip: { error, tripdata
      ,spEvents },
    trip: {
      tripdata: { lat, longi },
      userList,
      coownerList,
    },
    isDialogOpen,
    setDialogOpen,
    initialValues,
    handleRecomendationClick,
    zIndex,
    setZIndex,
    fetchOtherTrip,
    duration,
    setDuration,
    height,
    setHeight,
    importTrip: { loading, activities },
    getCoownerProfile,
    setCoowner
  } = props;

  const [searchParams, setSearchParams] = React.useState({});
  const [importChips, setImportChips] = React.useState([]);

  if (tripdata) {
    tripdata.type = tripdata.trip_access_type;
  }

  console.log(props);

  React.useEffect(() => {
    if (search) {
      setSearchParams(parse(search));
    }
    if (importType === "import") {
      setImportChips([parse(search)]);
    }
  }, [importType, search]);

  React.useEffect(() => {
    if (importType) {
      if (importType === "community") {
        fetchOtherTrip(tripId, COMMUNITY_ID);
      } else if (importType === "following") {
        fetchOtherTrip(tripId, FRIENDS_ID);
      }
    }
  }, [fetchOtherTrip, importType, tripId]);

  React.useEffect(() => {
    if (!loading && activities && activities.length) {
      if (tripId) {
        const parsedActivities = [];
        activities.forEach((active) => {
          parsedActivities.push(activityImportChip(getImportActivity(active)));
        });
        setImportChips(parsedActivities);
        console.log(parsedActivities);
      }
    }
  }, [loading, activities, tripId]);

  const removeImportChip = (index) => {
    const copy = [...importChips];
    copy.splice(index, 1);
    setImportChips(copy);
  };

  const getMakers = React.useCallback(() => {
    const markers = createMarkers(tripValues);
    return markers;
  }, [tripValues]);

  if (error && action === "edit") {
    return <Redirect to={"/trip-explorer/"+id+"/following"} />
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  return (
    <>
      <GridContainer container>
        <Hidden smDown>
          <Resizable
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
            handleStyles={{
              right: {
                width: "15px",
                right: "-8px",
                zIndex: 4,
              },
            }}
            defaultSize={{ width: "50%", height: "auto" }}
            minWidth="38%"
            maxWidth="80%"
          >
            <MapAndPlannerComponent
              lat={lat}
              longi={longi}
              markers={getMakers()}
              handleRecomendationClick={handleRecomendationClick}
              initialValues={initialValues}
              setDialogOpen={setDialogOpen}
              history={history}
              action={action}
              setDuration={setDuration}
              duration={duration}
              currHeight={height}
              resizeCallback={(a, b, c, d) => setHeight(c.scrollHeight)}
              renderMap={(props) => <DesktopMap {...props} />}
              importChips={importChips}
              removeImportChip={removeImportChip}
            />
          </Resizable>
        </Hidden>
        <Hidden mdUp>
          <MapAndPlannerComponent
            lat={lat}
            longi={longi}
            markers={getMakers()}
            handleRecomendationClick={handleRecomendationClick}
            initialValues={initialValues}
            setDialogOpen={setDialogOpen}
            history={history}
            action={action}
            setDuration={setDuration}
            duration={duration}
            renderMap={(props) => <MobileMap {...props} />}
            currHeight={height}
            importChips={importChips}
            removeImportChip={removeImportChip}
          />
        </Hidden>
        <GoogleSearchContainer item>
          <GoogleSearch zIndex={zIndex} setZIndex={setZIndex} />
        </GoogleSearchContainer>
      </GridContainer>
      {action === "create" && (
        <NewTourDialog
          heading="Start Planning your Activities"
          subheading="Please enter these information to start planning activities"
          open
          form="create-trip"
          buttonText="Start Planning"
          onSubmit={submitCreateTrip}
          handleClose={() => history.push("/profile")}
          lat={+searchParams.lat}
          lng={+searchParams.longi}
          initialValues={searchParams ? searchParams : { type: "1" }}
          getCoownerProfile={getCoownerProfile}
          coowners={coownerList}
          userList={userList}
          setCoowner={setCoowner}
        />
      )}
      {isDialogOpen && (
        <NewTourDialog
          lat={+lat}
          lng={+longi}
          open={isDialogOpen}
          initialValues={tripdata}
          form="edit-trip"
          heading="Edit Trip Information"
          buttonText="Save Changes"
          handleClose={() => setDialogOpen(false)}
          onSubmit={submitEditTrip}
          coowners={coownerList}
          getCoownerProfile={getCoownerProfile}
          userList={userList}
          setCoowner={setCoowner}
        />
      )}
    </>
  );
};

export default TourPlanPure;
