import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import LoadingButton from "../../common/LoadingButton";

export const GridContainer = styled(Grid)`
  background: ${(props) => props.theme.appBar};
`;

export const StyledDialog = styled(({ ...other }) => (
  <Dialog
    {...other}
    classes={{
      paper: "paperContainer",
    }}
  />
))`
  .dialog-title {
    background: ${(props) => props.theme.primaryBlack};
  }
  .dialog-heading {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .dialog-form {
    padding: 10px 24px 24px 24px;
  }

  .subheading {
    font-size: 1rem;
    font-weight: 600;
  }
  && .paperContainer {
    background: ${(props) => props.theme.appBar};
    border-radius: 15px;
  }
  .coo-delete {
    padding: 0;
    span {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const StyledLoadingButton = styled(LoadingButton)`
  border-radius: 16px;
`;
