import {
  GET_TRIP_REQUEST,
  GET_TRIP_FAILURE,
  GET_TRIP_SUCCESS,
  GET_IMPORT_TRIP_REQUEST,
  GET_IMPORT_TRIP_FAILURE,
  GET_IMPORT_TRIP_SUCCESS,
  GET_COOWNER_PROFILE,
  COOWNER_LIST,
  SPEVENT_LIST
} from "./types";
import { createTripLock, fetchCoownersApi, fetchTripApi, geCoownerApi, getSpecialEventList } from "./services";
import { enqueueSnackbar } from "../../Master/Notifier/duck";
import { MY_ID } from "../../constants";

export const fetchTrip = (id, type) => {

  return (dispatch, getState) => {
    dispatch({
      type: GET_TRIP_REQUEST
    });
    const state = getState();
    console.log(type);
    return fetchTripApi(id, !type ? MY_ID : type, state.auth.token)
      .then(res => {
        fetchCoownersApi(id, state.auth.token)
          .then(coo => {

            if (type == MY_ID) {
              createTripLock(id, state.auth.userData.userId, state.auth.token)
                .then(lock => {
                  if (lock.status !== 200) {
                    dispatch({
                      type: GET_TRIP_FAILURE,
                      payload: res.data
                    });
                    dispatch(
                      enqueueSnackbar({
                        message: "Trip is locked by one of the Co-Owners. Please try again later.",
                        variant: "error"
                      })
                    );
                  }
                  if (res.data.status == 200 && coo.status === 200) {
                    res.data.tripdata.coowners = coo.data;
                    dispatch({
                      type: GET_TRIP_SUCCESS,
                      payload: res.data,
                    });
                  } else {
                    dispatch({
                      type: GET_TRIP_FAILURE,
                      payload: res.data
                    });
                    dispatch(
                      enqueueSnackbar({
                        message: "Something went wrong",
                        variant: "error"
                      })
                    );
                  }
                })
                .catch(err => {
                  dispatch(
                    enqueueSnackbar({
                      message: "Trip is locked by one of the Co-Owners. Please try again later.",
                      variant: "error"
                    })
                  );
                  dispatch({
                    type: GET_TRIP_FAILURE,
                    payload: err
                  });
                });
            } else {
              if (res.data.status == 200 && coo.status === 200) {
                res.data.tripdata.coowners = coo.data;
                dispatch({
                  type: GET_TRIP_SUCCESS,
                  payload: res.data,
                });
              } else {
                dispatch({
                  type: GET_TRIP_FAILURE,
                  payload: res.data
                });
                dispatch(
                  enqueueSnackbar({
                    message: "Something went wrong",
                    variant: "error"
                  })
                );
              }
            }

          });
      })
      .catch(err => {
        dispatch(
          enqueueSnackbar({
            message: "Something went wrong",
            variant: "error"
          })
        );
        dispatch({
          type: GET_TRIP_FAILURE,
          payload: err
        });
      });
  };
};

export const fetchOtherTrip = (id, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_IMPORT_TRIP_REQUEST
    });
    const state = getState();
    try {
      const res = await fetchTripApi(id, type, state.auth.token);
      if (res.data.status === 200) {
        dispatch({
          type: GET_IMPORT_TRIP_SUCCESS,
          payload: res.data
        });
      }
      else {
        dispatch({
          type: GET_IMPORT_TRIP_FAILURE,
          payload: res.data
        });
      }
    }
    catch (err) {
      dispatch({
        type: GET_IMPORT_TRIP_FAILURE,
        payload: err
      });
    }
  };
};

export const getCoowner = (name) => {
  return (dispatch, getState) => {
    const state = getState();
    return geCoownerApi(state.auth.token, name)
      .then((res) => {
        console.log('Response', res);
        if (res.data.status === 200) {
          dispatch({
            type: GET_COOWNER_PROFILE,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error",
          })
        );
      });
  };
};

export const setCoowner = (coownerList) => {
  return (dispatch, getState) => {
    const state = getState();
    console.log(coownerList);
    dispatch({
      type: COOWNER_LIST,
      payload: coownerList,
    });
  };
};

export const getSpEvents = () => {
  return (dispatch, getState) => {
    const state = getState();
    return getSpecialEventList(state.auth.token)
      .then((res) => {
        console.log('Response of Events', res);
        if (res.data.status === 200) {
          dispatch({
            type: SPEVENT_LIST,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error",
          })
        );
      });
  };
};
export const setSpEvents = (spEventList) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: SPEVENT_LIST,
      payload: spEventList,
    });
  };
};
