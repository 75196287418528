import {
  GET_TRIP_REQUEST,
  GET_TRIP_SUCCESS,
  GET_TRIP_FAILURE,
  UPDATE_TRIP,
  GET_IMPORT_TRIP_REQUEST,
  GET_IMPORT_TRIP_FAILURE,
  GET_IMPORT_TRIP_SUCCESS,
  GET_COOWNER_PROFILE,
  COOWNER_LIST,
  SPEVENT_LIST,
} from "./types";
import { LOGIN_REQUEST } from "../../Onboarding/duck";
import {
  COMMUNITY_PHOTO_LIKE_SUCCESS,
  FRIEND_PHOTO_LIKE_SUCCESS
} from "../../Profile/Photos/duck";

const INITIAL_STATE = {
  loading: true,
  error: undefined,
  data: [],
  tripdata: {},
  userList: [],
  coownerList: [],
  spEvents: [],
};

export const tripReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRIP_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_TRIP_SUCCESS: {
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
        tripdata: action.payload.tripdata
      };
    }

    case FRIEND_PHOTO_LIKE_SUCCESS: {
      if (action.from) {
        const newData = state.data.map(day => {
          if (day.activitydata) {
            day.activitydata.forEach(active => {
              active.trippic.forEach(pic => {
                if (pic.photo_id === action.photoId) {
                  pic.liked = !pic.liked;
                }
              });
            });
          }
          return day;
        });
        return {
          ...state,
          data: newData
        };
      }
      return state;
    }

    case COMMUNITY_PHOTO_LIKE_SUCCESS: {
      if (action.from) {
        const newData = state.data.map(day => {
          if (day.activitydata) {
            day.activitydata.forEach(active => {
              active.trippic.forEach(pic => {
                if (pic.photo_id === action.photoId) {
                  pic.liked = !pic.liked;
                }
              });
            });
          }
          return day;
        });
        return {
          ...state,
          data: newData
        };
      }
      return state;
    }

    case UPDATE_TRIP: {
      const existingTripData = Object.assign({}, { ...state.tripdata });
      const tripDataKeys = Object.keys(existingTripData);
      const newTripData = Object.assign({}, { ...action.payload });
      const newData = {};
      console.log(action.payload);
      tripDataKeys.forEach(key => {
        if (newTripData[key]) {
          newData[key] = newTripData[key];
        } else {
          newData[key] = existingTripData[key];
        }
      });
      newData.trip_access_type = newTripData.type;
      newData.coownerList = action.payload.coOwners;
      newData.coowners = action.payload.coOwners;
      const oldState = Object.assign({}, state);
      delete oldState.tripdata;
      oldState.tripdata = newData;
      return oldState;
    }

    case GET_TRIP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        tripdata: {}
      };
    }

    case LOGIN_REQUEST: {
      return INITIAL_STATE;
    }

    case GET_COOWNER_PROFILE: {
      return {
        ...state,
        userList: action.payload,
      }
    }

    case COOWNER_LIST: {
      return {
        ...state,
        coownerList: action.payload,
      }
    }

    case SPEVENT_LIST:
      return {
        ...state,
        spEvents: action.payload,
      }

    default:
      return state;
  }
};

const INITIAL_IMPORT_STATE = {
  loading: false,
  error: undefined,
  activities: undefined
};

export const importTripReducer = (state = INITIAL_IMPORT_STATE, action) => {
  switch (action.type) {
    case GET_IMPORT_TRIP_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    case GET_IMPORT_TRIP_SUCCESS: {
      // get all the activities from the trip
      const tripData = action.payload.data;
      const activities = [];
      if (tripData.length) {
        tripData.forEach(day => {
          if (day.activitydata && day.activitydata.length) {
            day.activitydata.forEach(active => {
              activities.push(active);
            });
          }
        });
      }
      return {
        loading: false,
        error: undefined,
        activities
      };
    }

    case GET_IMPORT_TRIP_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }

    default:
      return state;
  }
};
