import React, { useRef, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Draggable } from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import ReduxFormTextField from "../../../common/FormFields/TextField";
import ReduxFormTextFieldMultiline from "../../../common/FormFields/TextFieldMultiline";

import {
  ActivityPointer,
  ActivityContainer,
  ActivityPointerCotainer,
  ActivityFieldGrid,
  DeleteIconGrid,
  ActivityGrid,
  DrivingTime,
  DrivingTimeContainer,
  IconGrid,
  ActivityTimeContainer,
  updateEventLocation,
} from "./styles";
import {
  minValue,
  required,
  requiredAddress,
} from "../../../common/FormFields/validations";
import { Field } from "redux-form";
import TextField from "../../../common/FormFields/TextField";
import FileInputField from "../../../common/FormFields/FileUpload";
import GoogleAutocomplete from "../../../common/FormFields/googlePlaceAutocomplete";
import { ReactComponent as DeleteIcon } from "../../../assets/planner/delete.svg";
import { ReactComponent as RecommendationIcon } from "../../../assets/planner/recommendation.svg";
import { ReactComponent as AttachmentIcon } from "../../../assets/planner/attachment.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/expand.svg";
import { ReactComponent as CollapseIcon } from "../../../assets/collapse.svg";
import GreenRoadIcon from "../../../assets/green-car.svg";
import defaultImg from "../../../assets/default-trip-pic.png";
import { ActivityColors, getActivitySvg } from "../../../common/utils";
import {
  ACTIVITY_TYPES,
  ADD_MEAL,
  RECOMMENDATION_HELP_TEXT,
} from "../../../constants";
import { docUploadApi } from "../../duck/services";
import { Typography } from "@material-ui/core";
import { EventBusyTwoTone } from "@material-ui/icons";

const getPlacename = (formValues, currentActivityIndex, currentDayIndex) => {
  let place = "";
  const plan = formValues.plan;

  for (let dayIndex = currentDayIndex; dayIndex >= 0; dayIndex--) {
    for (
      let activityIndex =
        dayIndex === currentDayIndex
          ? currentActivityIndex - 1
          : plan[dayIndex] && plan[dayIndex].activities
          ? plan[dayIndex].activities.length
          : 0;
      activityIndex >= 0 && !place;
      activityIndex--
    ) {
      if (
        plan[dayIndex].activities[activityIndex].activityname &&
        plan[dayIndex].activities[activityIndex].to &&
        plan[dayIndex].activities[activityIndex].to.city
      ) {
        place = plan[dayIndex].activities[activityIndex].to;
        break;
      }

      if (
        plan[dayIndex] &&
        plan[dayIndex].activities &&
        plan[dayIndex].activities[activityIndex] &&
        plan[dayIndex].activities[activityIndex].activityname
      ) {
        place = plan[dayIndex].activities[activityIndex].activityname;
        break;
      }
    }
    if (!place && plan[dayIndex] && plan[dayIndex].startingplace) {
      place = plan[dayIndex].startingplace;
      break;
    }
  }
  return place ? place : { address: "" };
};
const getActivityCategory = (plan, currentActivityIndex, currentDayIndex) => {
  if (
    plan &&
    plan[currentDayIndex] &&
    plan[currentDayIndex].activities &&
    plan[currentDayIndex].activities[currentActivityIndex]
  ) {
    return plan[currentDayIndex].activities[currentActivityIndex]
      .activitycategory;
  }
};

const getActivityName = (plan, currentActivityIndex, currentDayIndex) => {
  if (
    plan &&
    plan[currentDayIndex] &&
    plan[currentDayIndex].activities &&
    plan[currentDayIndex].activities[currentActivityIndex]
  ) {
    return plan[currentDayIndex].activities[currentActivityIndex].activityname
      ?.address;
  }
};

const getActivityType = (plan, currentActivityIndex, currentDayIndex) => {
  if (
    plan &&
    plan[currentDayIndex] &&
    plan[currentDayIndex].activities &&
    plan[currentDayIndex].activities[currentActivityIndex]
  ) {
    return plan[currentDayIndex].activities[currentActivityIndex].activitytype;
  }
};
const getEvent = (plan, currentActivityIndex, currentDayIndex) => {
  if (
    plan &&
    plan[currentDayIndex] &&
    plan[currentDayIndex].activities &&
    plan[currentDayIndex].activities[currentActivityIndex]
  ) {
    return plan[currentDayIndex].activities[currentActivityIndex].eventname;
  }
};
const renderActivityIcon = (plan, actIndex, dayIndex) => {
  const type = getActivityType(plan, actIndex, dayIndex);
  return getActivitySvg(type);
};

const renderActivityType = (
  plan,
  actIndex,
  dayIndex,
  lat,
  lng,
  activity,
  updateEventLocation,
  spEvents
) => {
  const type = getActivityType(plan, actIndex, dayIndex);

  const actCat = getActivityCategory(plan, actIndex, dayIndex);

  const handleEventChange = (event) => {
    const actLoc = {
      address: event.address,
      lat: event.lat,
      lng: event.long,
      city: event.city,
    };
    console.log(actLoc);
    updateEventLocation(`${activity}.activityname`, actLoc);
  };
  const styles = {
    regTxt: {
      fontSize: "1rem",
      color: "#fff",
      letterSpacing: "0.005em",
      fontFamily: "Work Sans, sans-serif",
    },
    img: {
      marginTop: "25px",
      marginRight: "15px",
      height: "8rem",
      width: "8rem",
    },
    widthContent: {
      width: "85%",
    },
  };
  if (actCat == "meal") {
    return null;
  }
  if (type === "flight" || type === "rail" || type === "road") {
    return (
      <ActivityFieldGrid direction="column" container item md={7} xs={12}>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Field
            lat={lat}
            lng={lng}
            name={`${activity}.activityname`}
            label="From"
            placeholder="From"
            component={GoogleAutocomplete}
            validate={requiredAddress}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            lat={lat}
            lng={lng}
            name={`${activity}.to`}
            label="To"
            placeholder="To"
            component={GoogleAutocomplete}
            validate={requiredAddress}
          />
        </Grid>
      </ActivityFieldGrid>
    );
  }
  if (type === "specialEvents") {
    return (
      <ActivityFieldGrid item md={7} xs={12}>
        <Field
          name={`${activity}.eventname`}
          label="Event Name"
          select
          placeholder="Event Name"
          component={TextField}
          validate={required}
          style={{ marginBottom: "10px" }}
        >
          {spEvents.map((option) => (
            <MenuItem
              key={option.event_id}
              value={option.event_id}
              onClick={() => {
                handleEventChange(option);
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Field>
        <Field
          lat={lat}
          lng={lng}
          name={`${activity}.activityname`}
          label="Location"
          placeholder="Location"
          component={GoogleAutocomplete}
          validate={requiredAddress}
        />
      </ActivityFieldGrid>
    );
  }

  return (
    <ActivityFieldGrid item md={7} xs={12}>
      <Field
        lat={lat}
        lng={lng}
        name={`${activity}.activityname`}
        label="Location"
        placeholder="Location"
        component={GoogleAutocomplete}
        validate={requiredAddress}
      />
    </ActivityFieldGrid>
  );
};

const renderSpecialEvent = (
  plan,
  actIndex,
  dayIndex,
  lat,
  lng,
  activity,
  spEvents
) => {
  const type = getActivityType(plan, actIndex, dayIndex);
  const event = getEvent(plan, actIndex, dayIndex);
  const styles = {
    regTxt: {
      fontSize: "1rem",
      color: "#fff",
      letterSpacing: "0.005em",
      fontFamily: "Work Sans, sans-serif",
      whiteSpace: "pre-wrap",
    },
    img: {
      marginTop: "25px",
      marginRight: "15px",
      height: "8rem",
      width: "8rem",
    },
    widthContent: {
      width: "85%",
    },
  };
  let eventData = spEvents.find((ele) => ele.event_id == event);
  if (type === "specialEvents") {
    return (
      <ActivityFieldGrid direction="column" container item md={12} xs={12}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={styles.widthContent}>
            <p style={styles.regTxt}>{eventData?.discription}</p>
          </div>
          <div style={{ marginLeft: "20px" }}>
            <p style={styles.regTxt}>{`INR ${eventData?.cost || 0}`}</p>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {eventData?.img && eventData?.img.length > 0
            ? eventData?.img.map((spImg) => (
                <img style={styles.img} src={spImg} alt="driving" />
              ))
            : null}
        </div>
      </ActivityFieldGrid>
    );
  }
};

const renderMisc = (plan, actIndex, dayIndex, currency, activity) => {
  const type = getActivityType(plan, actIndex, dayIndex);
  const actCat = getActivityCategory(plan, actIndex, dayIndex);
  if (type != "specialEvents" && actCat !== "meal") {
    return (
      <div>
        <Field
          name={`${activity}.activitycost`}
          label="Cost"
          type="number"
          className="form-input"
          id="standard-adornment-amount"
          currency={currency}
          component={ReduxFormTextField}
          validate={minValue}
        ></Field>
        <Field
          name={`${activity}.note`}
          label="Note"
          type="text"
          className="form-input"
          component={ReduxFormTextFieldMultiline}
        ></Field>
      </div>
    );
  } else if (actCat == "meal") {
    return (
      <div>
        <Field
          name={`${activity}.note`}
          label="Note"
          type="text"
          className="form-input"
          component={ReduxFormTextFieldMultiline}
        ></Field>
      </div>
    );
  }
};

const renderActivityTypeButton = (
  plan,
  actIndex,
  dayIndex,
  currency,
  activity,
  addMealtoActivity
) => {
  const actCat = getActivityCategory(plan, actIndex, dayIndex);

  const handleMealChange = () => {
    addMealtoActivity(`${activity}.activityname.address`, "");
  };

  if (actCat != "meal") {
    return (
      <div>
        <Field
          name={`${activity}.activitytype`}
          label="Type"
          select
          placeholder="Activity Type"
          component={TextField}
          validate={required}
          style={{ marginBottom: "10px" }}
        >
          {ACTIVITY_TYPES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      </div>
    );
  } else {
    return (
      <div>
        <Field
          name={`${activity}.activitytype`}
          label="Type"
          select
          placeholder="Activity Type"
          component={TextField}
          validate={required}
          style={{ marginBottom: "10px" }}
        >
          {ADD_MEAL.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleMealChange()}
            >
              {option.label}
            </MenuItem>
          ))}
        </Field>
      </div>
    );
  }
};

const renderMeal = (
  plan,
  actIndex,
  dayIndex,
  activity,
  addMealtoActivity,
  dayKey
) => {
  const styles = {
    btn: {
      border: "1px solid #fff",
      color: "#fff",
      backgroundColor: "transparent",
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      marginRight: "10px",
    },
    btnActive: {
      border: "1px solid #4fc099",
      color: "#fff",
      backgroundColor: "#4fc099",
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      marginRight: "10px",
    },
    btnExtra: {
      border: "1px solid #F26724",
      color: "#fff",
      backgroundColor: "#F26724",
      marginTop: "20px",
      padding: "10px",
      borderRadius: "5px",
      marginRight: "10px",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap",
    },
    img: {
      marginTop: "25px",
      marginRight: "15px",
      height: "20px",
      width: "20px",
    },
    flexRow: {
      display: "flex",
      flexDirection: "row",
    },
    flexColumn: {
      display: "flex",
      flexDirection: "column",
    },
  };
  const type = getActivityType(plan, actIndex, dayIndex);
  const actCat = getActivityCategory(plan, actIndex, dayIndex);
  if (actCat !== "meal") {
    return null;
  }
  const activityname = getActivityName(plan, actIndex, dayIndex);
  let selectedArray;
  if (type == "breakfast" || type == "eveningSnacks") {
    selectedArray = activityname?.split(",") || [];
  } else {
    try {
      selectedArray = JSON.parse(activityname && activityname !== '' ? activityname : '[]');
    }
    catch(err) {
      selectedArray = [];
    }
    
  }
  const breakfastList = [
    "Kanda Batata poha/Mutter poha",
    " Upma chatni",
    "Veg Sandwich",
    "Bhel puri",
    " Batata wada / Kela wada",
    "Pani puri",
    "Mug khakhra",
    "Idli sambhar chatni",
    "Wada sambhar chatni",
    "Dosa sambhar chatni",
    "Aloo/Kela paratha with curd",
    "Misal Pav",
    "Dal Pakwaan",
    "Puri Bhaji",
    "Mix bhajiya",
    "Pav bhaji",
  ];
  const lunchList = [
    {
      categoryName: "Pick bread choice(Any 1)",
      value: ["Puri", "Paratha", "Tandoori roti"],
      free: 1,
    },
    {
      categoryName: "Raita choice(Any 1)",
      value: [
        "Plain curd",
        "Boondi raita",
        "Veg raita",
        "Cucumber raita",
        "Pineapple raita",
      ],
      free: 1,
    },
    {
      categoryName: "Dal/Kadhi choice (Any 1)",
      value: [
        "Dal makhani",
        "Dal tadka",
        "Dal fry",
        "Kadhi pakoda",
        "White kadhi",
      ],
      free: 1,
    },
    {
      categoryName: "Rice choice(Any 1)",
      value: [
        "Steam rice",
        "Jeera rice",
        "Corn rice",
        "Green peas pulav",
        "Tawa pulav",
        "Veg biryani",
        "None",
      ],
      free: 1,
    },
    {
      categoryName: "Vegetable choices(Any 2)",
      value: [
        "Paneer Makhanwala",
        "Paneer butter Masala",
        "Paneer tikka masala",
        "Paneer bhurji",
        "Paneer Kofta",
        "Mix veg dry",
        "Veg kolhapuri",
        " Veg makhanwala",
        "Veg korma",
        "Bhindi Masala",
        "Cabbage peas",
        "Aloo Gobi",
        "Aloo Mutter",
        "Dum Aloo",
        "Aloo Jeera",
        "Chole",
        "Rajma Masala",
      ],
      free: 2,
    },
    {
      categoryName: "Farsan choice (Any 1)",
      value: [
        "Mini Samosa",
        "Harabhara Kebab",
        "Veg Cutlet",
        "White Dhokla",
        "Khandvi",
        "Spring Roll",
        "Corn bhajiya",
        "Khaman",
      ],
      free: 1,
    },
    {
      categoryName: "Sweet choice (Any 1)",
      value: [
        "Gulab Jamun",
        "Rasgulla",
        "Malai Sandwich",
        "Kesar Basundi",
        "Shrikhand",
        "Ice-cream",
        "Malai Kulfi",
        "Kheer",
        "Pineapple Seera",
      ],
      free: 1,
    },
  ];
  const eveningSnacksList = [
    "Bhel Puri",
    "Sev puri",
    "Pani puri",
    "Batata wada",
    "Mix bhajiya",
    "Veg sandwich",
    "Misal Pav",
    "Pav bhaji",
  ];
  const dinnerList = [
    {
      categoryName: "Fastfood choices (Any 4)",
      value: [
        "Fried rice with Manchurian gravy",
        "Paneer chilly",
        "Manchurian dry",
        "Noodles with manchurian gravy",
        "Veg cheese pizza",
        "White sauce pasta",
        "Red sauce pasta ",
        "Bhel puri",
        "Sev puri",
        "Pani Puri",
        "Dahi Puri",
        "Ragda pattice",
        "Chole samosa",
        "Pav bhaji",
        "Misal pav",
        "Veg biryani",
      ],
      free: 4,
    },
    {
      categoryName: "Pick bread choice(Any 1)",
      value: ["Puri", "Paratha", "Tandoori roti"],
      free: 1,
    },
    {
      categoryName: "Raita choice(Any 1)",
      value: [
        "Plain curd",
        "Boondi raita",
        "Veg raita",
        "Cucumber raita",
        "Pineapple raita",
      ],
      free: 1,
    },
    {
      categoryName: "Dal/Kadhi choice (Any 1)",
      value: [
        " Dal makhani",
        "Dal tadka",
        "Dal fry",
        "Kadhi pakoda",
        "White kadhi",
      ],
      free: 1,
    },
    {
      categoryName: "Rice choice(Any 1)",
      value: [
        "Steam rice",
        "Jeera rice",
        "Corn rice",
        "Green peas pulav",
        "Tawa pulav",
        "Veg biryani",
        "None",
      ],
      free: 1,
    },
    {
      categoryName: "Vegetable choices(Any 2)",
      value: [
        " Paneer Makhanwala",
        "Paneer butter Masala",
        "Paneer tikka masala",
        "Paneer mutter",
        "Paneer bhurji",
        "Paneer Kofta",
        "Mix veg dry",
        "Veg kolhapuri",
        "Veg makhanwala",
        "Veg korma",
        "Bhindi Masala",
        "Cabbage peas",
        "Aloo Gobi",
        "Aloo Mutter",
        "Dum Aloo",
        "Aloo Jeera",
        "Chole",
        "Rajma Masala",
      ],
      free: 2,
    },
    {
      categoryName: "Sweet choice (Any 1)",
      value: [
        "Gulab Jamun",
        "Rasgulla",
        "Malai Sandwich",
        "Kesar Basundi",
        "Shrikhand",
        "Ice-cream",
        "Malai Kulfi",
        "Kheer",
        "Pineapple Seera",
      ],
      free: 1,
    },
  ];
  let minValue = 1;
  if (type == "breakfast") {
    minValue = 2;
  } else if (type == "lunch") {
    minValue = 8;
  } else if (type == "eveningSnacks") {
    minValue = 1;
  } else if (type == "dinner") {
    minValue = 15;
  }
  const handleToggleBreakFastSelection = (index, list) => {
    const itemIndex = selectedArray.findIndex((ele) => ele == list[index]);
    if (itemIndex == -1) {
      selectedArray.push(list[index]);
    } else {
      selectedArray.splice(itemIndex, 1);
    }
    addMealtoActivity(
      `${activity}.activityname.address`,
      selectedArray.toString()
    );
  };
  const handleToggleLunchSelection = (catIndex, itemIndex, list) => {
    const index = selectedArray.findIndex(
      (ele) =>
        ele.catIndex == catIndex &&
        ele.itemName == list[catIndex].value[itemIndex]
    );
    console.log(selectedArray);
    console.log(index, catIndex, itemIndex, list[catIndex].value[itemIndex]);
    if (index == -1) {
      selectedArray.push({
        catIndex: catIndex,
        itemIndex: itemIndex,
        itemName: list[catIndex].value[itemIndex],
      });
    } else {
      selectedArray.splice(index, 1);
    }
    addMealtoActivity(
      `${activity}.activityname.address`,
      JSON.stringify(selectedArray)
    );
  };

  const getBtnStyle = (item, sArray, limit) => {
    const type = getActivityType(plan, actIndex, dayIndex);
    let sIndex;
    if (type == "breakfast" || type == "eveningSnacks") {
      sIndex = sArray?.findIndex((a) => {
        return a == item;
      });
    } else {
      sIndex = sArray
        ?.filter((s) => s.catIndex == item.catIndex)
        .findIndex((a) => {
          return a.itemName == item.itemName;
        });
    }
    return sIndex == -1
      ? styles.btn
      : sIndex < limit
      ? styles.btnActive
      : styles.btnExtra;
  };
  return (
    <div style={styles.flexColumn}>
      <div>
        <p>
          <i>{ADD_MEAL.find((a) => a.value == type)?.sublabel}</i>
        </p>
        <Grid item sm={12} md={12}>
          {type == "breakfast" && (
            <Grid item md={12}>
              <div style={styles.flex}>
                {breakfastList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      handleToggleBreakFastSelection(index, breakfastList)
                    }
                    style={getBtnStyle(item, selectedArray, 2)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </Grid>
          )}

          {type == "lunch" && (
            <Grid item md={12}>
              {lunchList.map((cat, inx) => (
                <div>
                  <h3>{cat.categoryName}</h3>
                  <div style={styles.flex}>
                    {cat.value.map((item, index) => (
                      <div
                        key={`${inx}-${index}`}
                        onClick={() =>
                          handleToggleLunchSelection(inx, index, lunchList)
                        }
                        style={getBtnStyle(
                          { catIndex: inx, itemIndex: index, itemName: item },
                          selectedArray,
                          cat.free
                        )}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Grid>
          )}
          {type == "eveningSnacks" && (
            <Grid item md={12}>
              <div style={styles.flex}>
                {eveningSnacksList.map((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      handleToggleBreakFastSelection(index, eveningSnacksList)
                    }
                    style={getBtnStyle(item, selectedArray, 1)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </Grid>
          )}
          {type == "dinner" && (
            <Grid item md={12}>
              {dinnerList.map((cat, inx) => (
                <div>
                  {inx == 1 ? (
                    <h1>
                      <center>OR</center>
                    </h1>
                  ) : null}
                  <h3>{cat.categoryName}</h3>
                  <div style={styles.flex}>
                    {cat.value.map((item, index) => (
                      <div
                        key={`${inx}-${index}`}
                        onClick={() =>
                          handleToggleLunchSelection(inx, index, dinnerList)
                        }
                        style={getBtnStyle(
                          { catIndex: inx, itemIndex: index, itemName: item },
                          selectedArray,
                          cat.free
                        )}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

const ActivityFieldArray = (props) => {
  const {
    fields,
    dayIndex,
    lat,
    lng,
    handleRecomendationClick,
    plan,
    actIndex,
    drivingTime,
    formValues,
    forwardRef,
    token,
    auth: {
      userData: { currency },
    },
    showSnackBar,
    dayKey,
    addMealtoActivity,
    updateEventLocation,
    spEvents,
  } = props;

  const scrollerRef = useRef(null);

  const [collapseList, setCollapseList] = useState([]);

  const toggleCollapse = (actIndex) => {
    const isCollapse = collapseList.findIndex((c) => c == actIndex);
    const temp = [...collapseList];
    if (isCollapse !== -1) {
      temp.splice(isCollapse, 1);
    } else {
      temp.push(actIndex);
    }
    console.log(temp);
    setCollapseList(temp);
  };

  return fields.map((activity, index) => (
    <React.Fragment>
      <Draggable
        key={index}
        draggableId={`activity-${dayIndex}-${index}`}
        index={index}
      >
        {(provided, snapshot) => (
          <div className={`activityMeal-${dayIndex}`} forwardRef={scrollerRef}>
            <ActivityContainer
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              <div className="activity-content" key={index}>
                <ActivityPointerCotainer {...provided.dragHandleProps}>
                  {index === 0 && (
                    <div style={{ position: "relative" }}>
                      {drivingTime[`day-${dayIndex}`][`day-${dayIndex}`] && (
                        <DrivingTimeContainer>
                          <img src={GreenRoadIcon} alt="driving" />
                          <DrivingTime color="primary">
                            {drivingTime[`day-${dayIndex}`][`day-${dayIndex}`]}
                          </DrivingTime>
                        </DrivingTimeContainer>
                      )}
                    </div>
                  )}

                  <ActivityPointer
                    color={ActivityColors[dayIndex % ActivityColors.length]}
                  >
                    <div>
                      <Typography variant="subtitle1">
                        {`${dayIndex + 1}`}
                        {(index + 10).toString(36)}
                      </Typography>
                    </div>
                    <div className="icon-div">
                      {renderActivityIcon(
                        (formValues || {}).plan,
                        index,
                        dayIndex
                      )}
                      <div>
                        {collapseList.findIndex((c) => c == index) == -1 ? (
                          <CollapseIcon
                            onClick={() => {
                              toggleCollapse(index);
                            }}
                          />
                        ) : (
                          <ExpandIcon
                            onClick={() => {
                              toggleCollapse(index);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </ActivityPointer>
                </ActivityPointerCotainer>
                <ActivityGrid container spacing={2}>
                  <Grid container item sm md xs={12} spacing={4}>
                    <ActivityFieldGrid item md={5} xs={12}>
                      {renderActivityTypeButton(
                        (formValues || {}).plan,
                        index,
                        dayIndex,
                        currency,
                        activity,
                        addMealtoActivity
                      )}
                      {renderMisc(
                        (formValues || {}).plan,
                        index,
                        dayIndex,
                        currency,
                        activity
                      )}
                    </ActivityFieldGrid>
                    {collapseList.findIndex((c) => c == index) == -1 ? (
                      <div>
                        {renderActivityType(
                          (formValues || {}).plan,
                          index,
                          dayIndex,
                          lat,
                          lng,
                          activity,
                          updateEventLocation,
                          spEvents
                        )}
                        {renderSpecialEvent(
                          (formValues || {}).plan,
                          index,
                          dayIndex,
                          lat,
                          lng,
                          activity,
                          spEvents
                        )}
                        {renderMeal(
                          (formValues || {}).plan,
                          index,
                          dayIndex,
                          activity,
                          addMealtoActivity,
                          dayKey
                        )}
                      </div>
                    ) : null}
                  </Grid>

                  <IconGrid item container>
                    <Grid item className="recommendation">
                      <Tooltip
                        placement="top"
                        classes={{
                          tooltip: props.classes.customWidth,
                        }}
                        title={RECOMMENDATION_HELP_TEXT}
                      >
                        <RecommendationIcon
                          onClick={() => {
                            handleRecomendationClick(
                              getPlacename(formValues, index, dayIndex),
                              formValues.plan[dayIndex].activities[index]
                                .activitytype
                            );
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item className="attachment">
                      {!(
                        formValues.plan[dayIndex].activities[index] &&
                        formValues.plan[dayIndex].activities[index].docname
                      ) && <AttachmentIcon />}
                      <Field
                        name={`${activity}.docname`}
                        component={FileInputField}
                        style={{ width: "16px" }}
                        clearable
                        withUpload
                        onUpload={({ file, data }, callback) => {
                          const formData = new FormData();
                          formData.append("upl", file);
                          formData.append("trip_activity_id", "1568");
                          docUploadApi(token, formData)
                            .then((res) => {
                              callback(res.data.docname);
                            })
                            .catch((err) => {
                              showSnackBar(
                                "Error while uploading doc",
                                "error"
                              );
                              callback(null);
                            });
                        }}
                      />
                    </Grid>
                    <DeleteIconGrid item>
                      <IconButton
                        className="activity-delete"
                        onClick={() => fields.remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </DeleteIconGrid>
                  </IconGrid>
                </ActivityGrid>
              </div>
              <div style={{ position: "relative" }}>
                {drivingTime[`day-${dayIndex}`][`activity-${index}`] && (
                  <ActivityTimeContainer>
                    <img src={GreenRoadIcon} alt="driving" />
                    <DrivingTime color="primary">
                      {drivingTime[`day-${dayIndex}`][`activity-${index}`]}
                    </DrivingTime>
                  </ActivityTimeContainer>
                )}
              </div>
            </ActivityContainer>
          </div>
        )}
      </Draggable>
    </React.Fragment>
  ));
};

export default ActivityFieldArray;
