import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { StyledDialog, PlanContainer } from "./styles";
import PlanHeading from "./PlanHeading";
import PlanDetails from "./PlanDetails";
import PhotoUploadDialog from "../PhotoUploadDialog";
import LoadingScreen from "../../common/LoadingScreen";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const PlanPure = (props) => {
  const {
    trip,
    isDialogOpen,
    setDialogOpen,
    selected,
    handleClickUpload,
    checkboxes,
    handleCheckboxChange,
    handleActivityNote,
    type,
    duration,
    fetchTrip,
    auth,
    spEvents,
  } = props;

  console.log("planprops:", props);

  const [loading, setLoading] = useState(false);
  console.log("loading:", loading);

  const makePDF = () => {
    const printSection = document.getElementById("capture");
    html2canvas(printSection, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      // document.body.appendChild(canvas);
      const componentWidth = printSection.offsetWidth;
      const componentHeight = printSection.offsetHeight;

      const orientation = componentWidth >= componentHeight ? "l" : "p";
      const img = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation,
        unit: "px",
      });
      // doc.text("Html to PDF", 10, 10);
      doc.internal.pageSize.width = componentWidth;
      doc.internal.pageSize.height = componentHeight;
      doc.addImage(img, "PNG", 0, 0, componentWidth, componentHeight);
      doc.save(`${trip?.tripdata?.name}-${trip?.tripdata?.user_name}.pdf`);
      setLoading(false);
    });
  };

  return (
    <>
      <PlanContainer>
        <Grid container id="capture" style={{ backgroundColor: "#2E2E2E" }}>
          {/* <Grid container> */}
          <Grid item xs={12}>
            <PlanHeading
              trip={trip}
              type={type}
              auth={auth}
              savePDF={makePDF}
              setLoading={setLoading}
            />
          </Grid>
          <Grid item xs={12} className="plan-container">
            <PlanDetails
              checkboxes={checkboxes}
              handleCheckboxChange={handleCheckboxChange}
              handleActivityNote={handleActivityNote}
              trip={trip}
              handleClickUpload={handleClickUpload}
              type={type}
              auth={auth}
              duration={duration}
              fetchTrip={fetchTrip}
              spEvents={spEvents}
            />
          </Grid>
        </Grid>
        {isDialogOpen && !type && (
          <PhotoUploadDialog
            open={isDialogOpen}
            selected={selected}
            handleClose={() => setDialogOpen(false)}
            initialValues={{
              privacy: ["3", "3", "3", "3", "3", "3", "3"],
              caption: selected.activity.comments,
            }}
            fetchTrip={fetchTrip}
          />
        )}
      </PlanContainer>
      <StyledDialog
        fullWidth={true}
        maxWidth="sm"
        fullScreen={loading}
        open={loading}
      >
        <LoadingScreen />
      </StyledDialog>
    </>
  );
};

export default PlanPure;
