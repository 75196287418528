import React from "react";
import { Tooltip } from "@material-ui/core";
import { StyledPhotoCard, ActivityName, StyledImg } from "./styles";

const getActivityName = (name, type) => {
	if (type == "dinner") {
	  let actName = type + " - ";
	  const jsonName = JSON.parse(name);
	  console.log(jsonName);
	  for (let i = 0; i < jsonName.length; i++) {
		console.log(jsonName[i].itemName);
		actName = actName + jsonName[i].itemName;
		if (i != jsonName.length - 1) {
		  actName = actName + ", ";
		}
	  }
	  return actName;
	} else if (type == "lunch") {
	  let actName = type + " - ";
	  const jsonName = JSON.parse(name);
	  console.log(jsonName);
	  for (let i = 0; i < jsonName.length; i++) {
		console.log(jsonName[i].itemName);
		actName = actName + jsonName[i].itemName;
		if (i != jsonName.length - 1) {
		  actName = actName + ", ";
		}
	  }
	  return actName + ", Salad, Papad, Aachar , Chaas, Roti(phulka)";
	} else if (type == "eveningSnacks") {
	  let actName = type + " - " + name + ", Tea, coffee, biscuits";
	  return actName;
	} else if (type == "breakfast") {
	  let actName = type + " - " + name + ", Tea, coffee, bread butter jam";
	  return actName;
	}
	const acName = name?.split(",");
	if (acName?.length > 2) {
	  const newName = acName.splice(0, 2);
	  return newName.join(" ,");
	}
	return acName;
  };

const PhotoCard = ({
	photo,
	activity_type,
	location,
	renderActions,
	onPhotoClick,
	renderHeader,
	ispersonal,
	style,
	imgStyle,
	btnStlye,
	className,
}) => {
	return (
		<StyledPhotoCard className={className} style={style}>
			{renderHeader && renderHeader(photo)}
			<StyledImg
				ispersonal={ispersonal ? 1 : 0}
				alt={photo.username}
				src={photo.trippic?.url}
				onClick={onPhotoClick}
				style={imgStyle}
			/>
			<div>
				<Tooltip title={location}>
					<ActivityName>{getActivityName(location, activity_type)}</ActivityName>
				</Tooltip>
			</div>
			<div style={btnStlye} className="action-btns">
				{renderActions && renderActions()}
			</div>
		</StyledPhotoCard>
	);
};

export default PhotoCard;
