import {
  GET_PERSONAL_PHOTOS_REQUEST,
  GET_PERSONAL_PHOTOS_SUCCESS,
  GET_PERSONAL_PHOTOS_FAILURE,
  GET_FOLLOWING_PHOTOS_REQUEST,
  GET_FOLLOWING_PHOTOS_SUCCESS,
  GET_COMMUNITY_PHOTOS_REQUEST,
  GET_COMMUNITY_PHOTOS_SUCCESS,
  GET_COMMUNITY_PHOTOS_FAILURE,
  GET_FOLLOWING_PHOTOS_FAILURE,
  FRIEND_PHOTO_LIKE_SUCCESS,
  COMMUNITY_PHOTO_LIKE_SUCCESS,
  USER_PHOTO_LIKE_SUCCESS,
} from "./types";

import {
  getPhotosApi,
  likePhotoApi,
  updatePrivacyApi,
  uploadPhotoApi,
  getUserPhotosApi,
} from "./services";

import { enqueueSnackbar } from "../../../Master/Notifier/duck";
import {
  API_ERROR,
  MY_ID,
  FRIENDS_ID,
  UPDATE_PRIVACY_SUCCESS,
  PHOTO_UPLOAD_SUCCESS,
} from "../../../constants";
import { compress } from "../../../common/utils";

const dispatchPHOTOS_SuccessActionsOnType = (
  tripType,
  dispatch,
  data,
  pageno,
  search
) => {
  if (tripType === MY_ID) {
    return dispatch({
      type: GET_PERSONAL_PHOTOS_SUCCESS,
      payload: data,
      pageno,
      isLast: !(data.length > 0),
      search,
    });
  }

  if (tripType === FRIENDS_ID) {
    return dispatch({
      type: GET_FOLLOWING_PHOTOS_SUCCESS,
      payload: data,
      pageno,
      isLast: !(data.length > 0),
      search,
    });
  }

  return dispatch({
    type: GET_COMMUNITY_PHOTOS_SUCCESS,
    payload: data,
    pageno,
    isLast: !(data.length > 0),
    search,
  });
};

const dispatchLikeActionsOnType = (
  tripType,
  dispatch,
  photoId,
  status,
  from
) => {
  if (tripType === MY_ID) {
    return dispatch({
      type: USER_PHOTO_LIKE_SUCCESS,
      photoId,
      status,
      from,
    });
  }

  if (tripType === FRIENDS_ID) {
    return dispatch({
      type: FRIEND_PHOTO_LIKE_SUCCESS,
      photoId,
      status,
      from,
    });
  }

  return dispatch({
    type: COMMUNITY_PHOTO_LIKE_SUCCESS,
    photoId,
    status,
    from,
  });
};

const dispatchPHOTOS_RequestActionsOnType = (tripType, dispatch) => {
  if (tripType === MY_ID) {
    return dispatch({
      type: GET_PERSONAL_PHOTOS_REQUEST,
    });
  }

  if (tripType === FRIENDS_ID) {
    return dispatch({
      type: GET_FOLLOWING_PHOTOS_REQUEST,
    });
  }

  return dispatch({
    type: GET_COMMUNITY_PHOTOS_REQUEST,
  });
};

const dispatchErrorActionsOnType = (tripType, dispatch) => {
  if (tripType === MY_ID) {
    return dispatch({
      type: GET_PERSONAL_PHOTOS_FAILURE,
    });
  }

  if (tripType === FRIENDS_ID) {
    return dispatch({
      type: GET_FOLLOWING_PHOTOS_FAILURE,
    });
  }

  return dispatch({
    type: GET_COMMUNITY_PHOTOS_FAILURE,
  });
};

export const getUserPhotos = (userId, pageno, name, callback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_PERSONAL_PHOTOS_REQUEST,
    });
    const state = getState();
    try {
      const res = await getUserPhotosApi(
        state.auth.token,
        userId,
        pageno,
        name
      );
      console.log("photores:", res);
      if (res.data.status === 200) {
        if (callback) {
          callback();
        }
        const data = res.data.data;
        dispatch({
          type: GET_PERSONAL_PHOTOS_SUCCESS,
          payload: data,
          pageno,
          isLast: !(data.length > 0),
          name,
        });
      }
    } catch (err) {
      if (callback) {
        callback();
      }
      if (err.response.data.status === 391) {
        dispatch({
          type: GET_PERSONAL_PHOTOS_SUCCESS,
          payload: [],
          pageno,
          isLast: !([].length > 0),
          name,
        });
      }
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
      return dispatch({
        type: GET_PERSONAL_PHOTOS_FAILURE,
      });
    }
  };
};

export const getPhotos = (tripType, pageno, name, callback) => {
  return async (dispatch, getState) => {
    dispatchPHOTOS_RequestActionsOnType(tripType, dispatch);
    const state = getState();
    try {
      const res = await getPhotosApi(state.auth.token, tripType, pageno, name);
      if (res.data.status === 200) {
        if (callback) {
          callback();
        }

        return dispatchPHOTOS_SuccessActionsOnType(
          tripType,
          dispatch,
          res.data.data,
          pageno,
          name
        );
      }
    } catch (err) {
      if (callback) {
        callback();
      }
      if (err.response.data.status === 391) {
        return dispatchPHOTOS_SuccessActionsOnType(
          tripType,
          dispatch,
          [],
          name
        );
      }
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
      dispatchErrorActionsOnType(tripType, dispatch);
    }
  };
};

export const likePhoto = (tripType, photoId, status, from) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      console.log("Call like photo api", photoId, status);
      const res = await likePhotoApi(state.auth.token, photoId, status);
      if (res.data.status === 200) {
        return dispatchLikeActionsOnType(
          tripType,
          dispatch,
          photoId,
          status,
          from
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
    }
  };
};

export const updatePhotoPrivacy = (photoId, type, callback) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await updatePrivacyApi(state.auth.token, photoId, type);
      if (res.data.status === 200) {
        if (callback) {
          callback(null, res.data);
        }
        dispatch(
          enqueueSnackbar({
            message: UPDATE_PRIVACY_SUCCESS,
            variant: "success",
          })
        );
      }
    } catch (err) {
      if (callback) {
        callback(err, null);
      }
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
    }
  };
};

export const uploadPhotos = (photos, photoData, callback) => {
  return async (dispatch, getState) => {
    const photoPromises = [];
    const state = getState();
    photos.forEach((photo) => {
      photoPromises.push(
        new Promise((res, rej) => {
          let filePromise = Promise.resolve(null);
          if (photoData[photo.id] && photoData[photo.id].file) {
            filePromise = compress(photoData[photo.id].file, 500);
          }
          return filePromise.then((compressFiled) => {
            let formData = new FormData();
            const formValues = {
              type: photo.privacy,
              upl: compressFiled,
              location: photo.location.address,
              city: photo.location.city,
              lat: photo.location.lat,
              longi: photo.location.lng,
            };
            Object.keys(formValues).forEach((key) => {
              formData.append([key], formValues[key]);
            });
            return uploadPhotoApi(state.auth.token, formData)
              .then((resp) => {
                if (resp.data.status === 200) {
                  return res(true);
                }
                return rej(resp);
              })
              .catch((err) => rej(err));
          });
        })
      );
    });
    try {
      const res = await Promise.all(photoPromises);
      if (callback) {
        callback(null, res.data);
      }
      dispatch(
        enqueueSnackbar({
          message: PHOTO_UPLOAD_SUCCESS,
          variant: "success",
        })
      );
    } catch (err_1) {
      if (callback) {
        callback(err_1, null);
      }
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
    }
  };
};
