import React from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import TripCard from "./TripCard";
import { TripCardCotainer } from "./styles";
import NoContent from "../../common/NoContent";
import { getTrips, getUserTrips } from "../duck";
import LoadingScreen from "../../common/LoadingScreen";
import { MY_ID, GENERIC_ERROR_MESSAGE } from "../../constants";
import NotFound from "../../common/NotFound";
import { uid } from "react-uid";
import FriendTripCard from "./TripCard/FriendTripCard";
import ImportTripDialog from "../../common/ImportTripDialog/ImportTripDialog";

const Personal = (props) => {
  const {
    error,
    loading,
    // trips,
    gettrips,
    pageno,
    isLast,
    search,
    userId,
    auth: {
      userData,
      // userData: { currency },
    },
  } = props;

  // console.log("personalprops:", props);

  const [isFetching, setIsFetching] = React.useState(false);
  const [isImportDialogOpen, setImportDialog] = React.useState(false);
  const [selectedTrip, setTrip] = React.useState();
  const [trips, setTrips] = React.useState([]);

  React.useEffect(() => {
    if (props.userId && props.userId !== props.auth?.userData?.userId) {
      setTrips(props.othertrips);
    } else {
      setTrips(props.trips);
    }
  }, [
    pageno,
    userId,
    props.auth?.userData?.userId,
    props.othertrips,
    props.userId,
    props.trips,
  ]);

  const handleScroll = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 390
    ) {
      setIsFetching(true);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    gettrips(0);
    return () => window.removeEventListener("scroll", handleScroll, false);
  }, [gettrips]);

  React.useEffect(() => {
    if (!isFetching || !trips || isLast || search) return;
    gettrips(pageno + 1, () => setIsFetching(false));
  }, [isFetching, trips, isLast, search, gettrips, pageno]);

  if (error) {
    return <NotFound errorMsg={GENERIC_ERROR_MESSAGE} />;
  }

  if (loading && !trips) {
    return <LoadingScreen />;
  }

  if (trips.length === 0) {
    return (
      <NoContent heading="Havent Started Planning yet?" alignText="center" />
    );
  }

  const renderTripCard = (trip) => {
    if (userId) {
      return (
        <FriendTripCard
          trip={trip}
          currency={userData?.currency ? userData?.currency : "INR"}
          // currency={currency}
          handleImportPlan={() => {
            setTrip(trip);
            setImportDialog(true);
          }}
          type="following"
        />
      );
    }

    return (
      <TripCard
        trip={trip}
        currency={userData?.currency ? userData?.currency : "INR"}
        // currency={currency}
        handleImportPlan={() => {
          console.log(trip);
          setTrip(trip);
          setImportDialog(true);
        }}
      />
    );
  };

  return (
    <>
      <Grid wrap="wrap" container spacing={4}>
        {trips.map((trip, index) => (
          <TripCardCotainer key={uid(trip, index)} item md={4} xs={12}>
            {renderTripCard(trip)}
          </TripCardCotainer>
        ))}
      </Grid>
      <ImportTripDialog
        isOpen={isImportDialogOpen}
        handleClose={() => setImportDialog(false)}
        tripToImport={selectedTrip}
        type="following"
        triptype={"trips"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    trips: { error, loading, trips, isLast, pageNo, search, othertrips },
  } = state;

  return {
    auth: state.auth,
    trips,
    othertrips,
    error,
    loading,
    isLast,
    pageno: pageNo,
    search,
  };
};

const mapDispatchToProps = (dispatch, { userId }) => {
  return {
    gettrips: (page, callback) => {
      if (userId) {
        return dispatch(getUserTrips(userId, page, undefined, callback));
      }
      return dispatch(getTrips(MY_ID, page, undefined, callback));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Personal);
