import { axiosInst, axiosConfig } from "../../axios";

export const deletePhotoApi = (photo_id, token) => {
  return axiosInst.post(
    "/removepic",
    {
      photo_id: String(photo_id)
    },
    axiosConfig(token)
  );
};

export const getSpecialEventList = (token) => {
  return axiosInst.post(
    "/getSpEvents",
    axiosConfig(token)
  );
};