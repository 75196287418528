import { axiosInst, axiosConfig } from "../../axios";

export const createTripApi = (values, token) => {
  return axiosInst.post("/createtrip", { ...values }, axiosConfig(token));
};

export const editTripApi = (values, token) => {
  return axiosInst.post("/edittrip", values, axiosConfig(token));
};

export const editCoownerApi = (values, token) => {
  return axiosInst.post("/editCoowners", values, axiosConfig(token));
};

export const fetchTripApi = (id, type, token) => {
  console.log("tripid:", id);
  console.log("triptype:", type);
  if (token) {
    return axiosInst.post(
      "/viewdetails",
      {
        trip_id: id,
        type: String(type),
      },
      axiosConfig(token)
    );
  }
  return axiosInst.post("/viewdetails", {
    trip_id: id,
    type: String(type),
  });
};

export const fetchCoownersApi = (id, token) => {
  return axiosInst.post(
    "/getCoowners",
    {
      trip_id: id,
    },
    axiosConfig(token)
  );
};

export const createTripLock = (trip_id, user_id, token) => {
  return axiosInst.post(
    "/lockTrip",
    {
      trip_id: trip_id,
      user_id: user_id,
    },
    axiosConfig(token)
  );
};

export const importTripApi = (values, token) => {
  return axiosInst.post("/importtrip", { ...values }, axiosConfig(token));
};

export const docUploadApi = (token, formData) => {
  return axiosInst.post("/docupload", formData, axiosConfig(token));
};

export const geCoownerApi = (token, name) => {
  return axiosInst.post(
    "/following",
    { type: 1, perpage: 10, pageno: 0, name },
    axiosConfig(token)
  );
};

export const getSpecialEventList = (token) => {
  return axiosInst.post("/getSpEvents", axiosConfig(token));
};
