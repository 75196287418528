import { GET_NEARBY_PLACES, RESET_NEARBY_PLACE, SPEVENT_LIST } from "./types";

const INITIAL_STATE = {
  place: undefined,
  type: undefined,
  spEvents: [],
};

export const nearbyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NEARBY_PLACES: {
      return {
        ...state,
        ...action.payload
      };
    }

    case RESET_NEARBY_PLACE: {
      return INITIAL_STATE;
    }

    case SPEVENT_LIST:
      console.log(action);
      return {
        ...state,
        spEvents: action.payload,
      }

    default:
      return state;
  }
};
