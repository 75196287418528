import { axiosInst, axiosConfig } from '../axios'

export const sendResetLink = (email) => {
  return axiosInst.post('/forgotpwd', {
    mobileEmail: email,
  })
}

export const setNewPassword = (password, token) => {
  return axiosInst.post(
    '/validatelink',
    {
      newpwd: password,
      token: token,
    },
    axiosConfig(token),
  )
}

export const loginApi = (values) => {
  return axiosInst.post('/login', values, axiosConfig(undefined, 'User'))
}

export const signupApi = (values) => {
  return axiosInst.post('/createuser', values)
}

export const addnewuserApi = (values) => {
  return axiosInst.post('/createuser', values)
}
