import { deletePhotoApi, getSpecialEventList } from "./services";
import { enqueueSnackbar } from "../../Master/Notifier/duck";
import { GET_NEARBY_PLACES, RESET_NEARBY_PLACE, SPEVENT_LIST } from "./types";

export const deletePhoto = (photoId, callback) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await deletePhotoApi(photoId, state.auth.token);
      if (res.data.status === 200) {
        dispatch(
          enqueueSnackbar({
            message: "Photo deleted",
            variant: "success"
          })
        );
        if (callback) {
          callback();
        }
      } else {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error"
          })
        );
      }
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: "Some error occured",
          variant: "error"
        })
      );
    }
  };
};

export const getNearbyPlaces = (place, type) => {
  return dispatch => {
    dispatch({
      type: GET_NEARBY_PLACES,
      payload: {
        place,
        type
      }
    });
  };
};

export const resetPlace = () => {
  return dispatch => {
    dispatch({
      type: RESET_NEARBY_PLACE
    });
  };
};

export const getSpEvents = () => {
  return (dispatch, getState) => {
    const state = getState();
    return getSpecialEventList(state.auth.token)
      .then((res) => {
        console.log('Response of Events', res);
        if (res.data.status === 200) {
          dispatch({
            type: SPEVENT_LIST,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error",
          })
        );
      });
  };
};
export const setSpEvents = (spEventList) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: SPEVENT_LIST,
      payload: spEventList,
    });
  };
};