import React from "react";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link } from "react-router-dom";
import moment from "moment";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography } from "@material-ui/core";
import {
  TripHolder,
  ImageHolder,
  TripName,
  StyledButton,
  StyledLink,
  StyledIconButton,
  StyledText,
  ActionGrid,
} from "./styles";
import defaultPic from "../../../assets/default-trip-pic.png";
import { ReactComponent as PhotosIcon } from "../../../assets/profile/photos.svg";
import { ReactComponent as CheckinsIcon } from "../../../assets/profile/checkins.svg";
import { ReactComponent as ImportIcon } from "../../../assets/profile/imports.svg";
import { StyledItem } from "../../Photos/styles";
import ImportTripDialog from "../../../common/ImportTripDialog/ImportTripDialog";

const TripCardPure = (props) => {
  const {
    anchorEl,
    setAnchorEl,
    deleteTrip,
    trip: {
      checkin,
      pic,
      name,
      from_dt,
      to_dt,
      trip_id,
      imports,
      totalpic,
      // trip_local_cost,
      trip_cost,
      role,
    },
    open,
    isOpen,
    currency,
    handleImportPlan,
  } = props;

  const renderDateName = () => {
    return (
      <Grid className="trip-card-heading" item xs={12}>
        <Tooltip disableFocusListener title={name} placement="top">
          <TripName variant="h4">{name}</TripName>
        </Tooltip>
        <Typography variant="body2">
          <Grid item md={12}>
            <Grid container alignItems="flex-end">
              <Grid item md={9}>
                {moment(from_dt).format("Do MMM")} -{" "}
                {moment(to_dt).format("Do MMM YY")} {"  "}
              </Grid>
              <Grid
                item
                md={3}
                alignContent="flex-end"
                style={{ color: "#08E8DE" }}
              >
                {`${currency} `}
                {trip_cost ? trip_cost : "0"}
              </Grid>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    );
  };

  return (
    <TripHolder container wrap="wrap">
      <Hidden smDown>{renderDateName()}</Hidden>
      <Grid item md={12} xs={4}>
        <ImageHolder src={pic?.url || defaultPic} />
      </Grid>
      <Grid item md={12} xs={8} container justify="space-between">
        <Hidden mdUp>{renderDateName()}</Hidden>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          className="meta-container"
        >
          <Grid item>
            <StyledText variant="h5">
              <CheckinsIcon />
              <span>{checkin}</span>
            </StyledText>
          </Grid>
          <Grid item>
            <StyledText variant="h5">
              <PhotosIcon />
              <span>{totalpic}</span>
            </StyledText>
          </Grid>
          <Grid item>
            <StyledText variant="h5">
              <ImportIcon />
              <span>{imports}</span>
            </StyledText>
          </Grid>
        </Grid>
        <ActionGrid
          container
          justify="space-between"
          item
          wrap="nowrap"
          alignItems="center"
          className="action-btn-container"
        >
          <Grid item>
            <StyledButton
              size="small"
              disableRipple
              component={Link}
              isGreen={1}
              to={
                role == "viewer"
                  ? `/trip-explorer/${trip_id}/following`
                  : `/trip-explorer/${trip_id}`
              }
            >
              CHECK-IN
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              size="small"
              disableRipple
              isGreen={1}
              onClick={handleImportPlan}
            >
              CLONE
            </StyledButton>
          </Grid>

          <Grid item>
            <StyledButton
              disableRipple
              size="small"
              component={Link}
              to={
                role == "viewer"
                  ? `/trip-explorer/${trip_id}/following`
                  : `/trip-plan/edit/${trip_id}/`
              }
            >
              {role != "viewer" ? `EDIT` : `VIEW`}
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledIconButton
              color="primary"
              aria-label="More"
              aria-owns={anchorEl ? "long-menu" : undefined}
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                isOpen(true);
              }}
            >
              <MoreHorizIcon />
            </StyledIconButton>
            <Popper
              id="long-menu"
              open={open}
              anchorEl={anchorEl}
              role={undefined}
              transition
              disablePortal
              style={{
                zIndex: 1,
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={() => isOpen(false)}>
                      <List>
                        <Hidden mdUp>
                          <StyledItem
                            component={StyledLink}
                            to={
                              role == "viewer"
                                ? `/trip-explorer/${trip_id}/following`
                                : `/trip-explorer/${trip_id}`
                            }
                          >
                            Check-in
                          </StyledItem>
                        </Hidden>
                        {role != "viewer" && (
                          <StyledItem
                            component={StyledLink}
                            to={
                              role == "viewer"
                                ? `/trip-explorer/${trip_id}`
                                : `/trip-plan/edit/${trip_id}`
                            }
                          >
                            Edit
                          </StyledItem>
                        )}

                        <StyledItem
                          onClick={() => {
                            deleteTrip(trip_id, checkin);
                            setAnchorEl(null);
                          }}
                        >
                          Delete
                        </StyledItem>
                      </List>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </ActionGrid>
      </Grid>
    </TripHolder>
  );
};

export default TripCardPure;
