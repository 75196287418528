import React from "react";
import NoPic from "../../assets/no-profile-pic.png";
import { StyledImg } from "../styles";
import { isWidthUp } from "@material-ui/core/withWidth";
import { ReactComponent as CheckinVector } from "../../assets/profile/checkins.svg";
import { ReactComponent as TripVector } from "../../assets/profile/trips.svg";
import { ReactComponent as FollowingVector } from "../../assets/profile/following.svg";
import { ReactComponent as FollowersVector } from "../../assets/profile/followers.svg";

import {
  ProfileGrid,
  StyledName,
  StyledHeadings,
  ProfileMobileContainer,
} from "./styles";
import { Typography } from "@material-ui/core";

const ProfileCardPure = (props) => {
  const {
    auth: { userData },
    checkins: {
      checkins: { activitycount, tripcount, followers, following },
    },
    width,
    userId,
  } = props;

  if (isWidthUp("sm", width)) {
    return (
      <ProfileGrid className="profile-card">
        <div className="photo-container">
          <div>
            <StyledImg
              alt="user-profile"
              src={userData?.profile_pic ? userData?.profile_pic?.url : NoPic}
            />
          </div>
          <div>
            <StyledName userId={userId ? 1 : 0} variant="h5">
              {userData?.first_name} {userData?.last_name}
            </StyledName>
            <Typography variant="body1" gutterBottom>
              @{userData?.user_name}
            </Typography>
          </div>
        </div>
        <div className="user-info-container">
          <StyledHeadings variant="body1" gutterBottom>
            <CheckinVector />
            {activitycount} Check-Ins
          </StyledHeadings>
          <StyledHeadings variant="body1" gutterBottom>
            <TripVector />
            {tripcount} Trips
          </StyledHeadings>
          <StyledHeadings variant="body1" gutterBottom>
            <FollowersVector />
            {followers} Followers
          </StyledHeadings>
          <StyledHeadings variant="body1" gutterBottom>
            <FollowingVector />
            {following} Following
          </StyledHeadings>
        </div>
      </ProfileGrid>
    );
  }

  return (
    <ProfileMobileContainer>
      <div className="photo-container">
        <div>
          <img
            alt="user-profile"
            src={userData?.profile_pic ? userData?.profile_pic?.url : NoPic}
          />
        </div>
        <div>
          <StyledName userId={userId ? 1 : 0} variant="h5">
            {userData?.first_name} {userData?.last_name}
          </StyledName>
          <Typography variant="body1" gutterBottom>
            @{userData?.user_name}
          </Typography>
        </div>
      </div>
      <div className="user-info-container">
        <StyledHeadings variant="body1" gutterBottom>
          <CheckinVector />
          {activitycount} Check-Ins
        </StyledHeadings>
        <StyledHeadings variant="body1" gutterBottom>
          <TripVector />
          {tripcount} Trips
        </StyledHeadings>
        <StyledHeadings variant="body1" gutterBottom>
          <FollowersVector />
          {followers} Followers
        </StyledHeadings>
        <StyledHeadings variant="body1" gutterBottom>
          <FollowingVector />
          {following} Following
        </StyledHeadings>
      </div>
    </ProfileMobileContainer>
  );
};

export default ProfileCardPure;
