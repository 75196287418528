import {
  GETTRIP_SUCCESS,
  GETTRIP_FAILURE,
  GETTRIP_REQUEST,
  DELETE_TRIP_REQUEST,
  DELETE_TRIP_SUCCESS,
  DELETE_TRIP_FAILURE,
  GET_CHECKIN_REQUEST,
  GET_CHECKIN_SUCCESS,
  GET_CHECKIN_FAILURE,
  GET_FRIEND_TRIP_SUCCESS,
  GET_COMMUNITY_TRIP_SUCCESS,
  GET_FRIEND_TRIP_REQUEST,
  GET_COMMUNITY_TRIP_REQUEST,
  GET_FRIEND_TRIP_ERROR,
  GET_COMMUNITY_TRIP_ERROR,
  CLEAR_MY_TRIPS,
} from "./types";

import {
  getTripApi,
  deleteTripApi,
  getCheckinApi,
  getUserCheckinApi,
  getUserTripsApi,
  getProfileApi,
} from "./services";

import { enqueueSnackbar } from "../../Master/Notifier/duck";
import { API_ERROR, MY_ID, FRIENDS_ID } from "../../constants";
import { UPDATE_USER_DATA } from "../../Onboarding/duck";

const dispatchRequestActionsOnType = (tripType, dispatch) => {
  if (tripType === MY_ID) {
    return dispatch({
      type: GETTRIP_REQUEST,
    });
  }

  if (tripType === FRIENDS_ID) {
    return dispatch({
      type: GET_FRIEND_TRIP_REQUEST,
    });
  }

  return dispatch({
    type: GET_COMMUNITY_TRIP_REQUEST,
  });
};

const dispatchErrorActionsOnType = (tripType, dispatch) => {
  if (tripType === MY_ID) {
    return dispatch({
      type: GETTRIP_FAILURE,
    });
  }

  if (tripType === FRIENDS_ID) {
    return dispatch({
      type: GET_FRIEND_TRIP_ERROR,
    });
  }

  return dispatch({
    type: GET_COMMUNITY_TRIP_ERROR,
  });
};

const dispatchSuccessActionsOnType = (
  tripType,
  dispatch,
  data,
  pageno,
  search
) => {
  if (tripType === MY_ID) {
    return dispatch({
      type: GETTRIP_SUCCESS,
      payload: data,
      pageno,
      isLast: !(data.length > 0),
      search,
    });
  }

  if (tripType === FRIENDS_ID) {
    return dispatch({
      type: GET_FRIEND_TRIP_SUCCESS,
      payload: data,
      pageno,
      isLast: !(data.length > 0),
      search,
    });
  }

  return dispatch({
    type: GET_COMMUNITY_TRIP_SUCCESS,
    payload: data,
    pageno,
    isLast: !(data.length > 0),
    search,
  });
};

export const getUserTrips = (userId, pageno, name, callback) => {
  return async (dispatch, getState) => {
    dispatch({
      type: GETTRIP_REQUEST,
    });
    const state = getState();
    try {
      const res = await getUserTripsApi(state.auth.token, userId, pageno, name);
      if (res.data.status === 200) {
        if (callback) {
          callback();
        }
        const data = res.data.data;
        dispatch({
          type: GETTRIP_SUCCESS,
          payload: data,
          pageno,
          isLast: !(data.length > 0),
          name,
          user: "otherUser",
        });
      }
    } catch (err) {
      if (callback) {
        callback();
      }
      if (err.response.data.status === 391) {
        dispatch({
          type: GETTRIP_SUCCESS,
          payload: [],
          pageno,
          isLast: !([].length > 0),
          name,
        });
      }
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
      return dispatch({
        type: GETTRIP_FAILURE,
      });
    }
  };
};

export const getTrips = (tripType, pageno, name, callback) => {
  return async (dispatch, getState) => {
    dispatchRequestActionsOnType(tripType, dispatch);
    const state = getState();
    try {
      const res = await getTripApi(tripType, name, state.auth.token, pageno);
      if (res.data.status === 200) {
        if (callback) {
          callback();
        }
        return dispatchSuccessActionsOnType(
          tripType,
          dispatch,
          res.data.data,
          pageno,
          name
        );
      }
    } catch (err) {
      if (callback) {
        callback();
      }
      if (err.response.data.status === 391) {
        return dispatchSuccessActionsOnType(tripType, dispatch, [], name);
      }
      dispatch(
        enqueueSnackbar({
          message: API_ERROR,
          variant: "error",
        })
      );
      dispatchErrorActionsOnType(tripType, dispatch);
    }
  };
};

export const clearMyTrips = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MY_TRIPS });
  };
};

export const deleteTrip = (trip_id, checkins) => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ type: DELETE_TRIP_REQUEST });
    return deleteTripApi(trip_id, state.auth.token)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: DELETE_TRIP_SUCCESS,
            payload: trip_id,
            checkinCount: checkins,
          });
          dispatch(
            enqueueSnackbar({
              message: "Trip deleted",
              variant: "success",
            })
          );
        } else {
          dispatch({
            type: DELETE_TRIP_FAILURE,
          });
          dispatch(
            enqueueSnackbar({
              message: API_ERROR,
              variant: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: API_ERROR,
            variant: "error",
          })
        );
        dispatch({
          type: DELETE_TRIP_FAILURE,
        });
      });
  };
};

export const getCheckins = (userId) => {
  return (dispatch, getState) => {
    dispatch({
      type: GET_CHECKIN_REQUEST,
    });
    const state = getState();

    if (userId) {
      return getUserCheckinApi(state.auth.token, userId)
        .then((res) => {
          console.log("checkinres:", res);
          console.log("checkinresaxios");
          if (res.data.status === 200) {
            dispatch({
              type: GET_CHECKIN_SUCCESS,
              payload: res.data,
            });
          } else if (res.data.status === 902) {
            dispatch({
              type: GET_CHECKIN_SUCCESS,
              payload: {
                data: [],
              },
            });
          }
        })
        .catch((err) => {
          dispatch(
            enqueueSnackbar({
              message: "Some error occured",
              variant: "error",
            })
          );
          dispatch({
            type: GET_CHECKIN_FAILURE,
            payload: err,
          });
        });
    }

    return getCheckinApi(state.auth.token)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: GET_CHECKIN_SUCCESS,
            payload: res.data,
          });
        } else if (res.data.status === 902) {
          dispatch({
            type: GET_CHECKIN_SUCCESS,
            payload: {
              data: [],
            },
          });
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error",
          })
        );
        dispatch({
          type: GET_CHECKIN_FAILURE,
          payload: err,
        });
      });
  };
};

export const getProfile = () => {
  return (dispatch, getState) => {
    const state = getState();
    return getProfileApi(state.auth.token)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("res:", res);
          dispatch({
            type: UPDATE_USER_DATA,
            payload: res.data.data,
          });
        }
      })
      .catch((err) => {
        dispatch(
          enqueueSnackbar({
            message: "Some error occured",
            variant: "error",
          })
        );
      });
  };
};
