import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

import { IconButton } from "@material-ui/core";

export const DayPointer = styled.div`
  background: ${(props) => props.color};
  margin-right: 20px;
  max-width: 75px;
  min-width: 75px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    max-width: 55px;
    min-width: 55px;
  }
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  white-space: nowrap;
  h6 {
    font-weight: 900;
    color: ${(props) => props.theme.appBar};
  }
  p {
    color: ${(props) => props.theme.appBar};
  }
`;

export const ActivityPointer = styled.div`
  margin-right: 20px;
  min-width: 65px;
  max-width: 65px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    max-width: 45px;
    min-width: 45px;
  }
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    color: ${(props) => props.color};
  }

  .icon-div {
    svg {
      min-height: 16px;
      min-width: 16px;
      height: 16px;
      width: 16px;
      fill: ${(props) => props.color};

      path {
        fill: ${(props) => props.color};
      }
    }
  }
  .activity-icon {
    height: 14px;
    width: 14px;
  }
`;

export const DayHeadingContainer = styled.div`
  display: flex;
  align-items: center;
  > p {
    font-size: 1.25rem;
    font-weight: 600;
  }
`;

export const ActivityContainer = styled.div`
  padding: 10px 0 5px 0;
  .activity-content {
    padding: 10px;
    display: flex;
    background: ${(props) => props.theme.secondaryBlack};
  }

  .pointer-and-icons {
  }

  hr {
    background-color: #383833;
  }

  .comments {
    color: ${(props) => props.theme.secondaryWhite};
  }
`;
export const VerticalDivider = styled.div`
  flex: 1;
  min-height: 56px;
  border-left: 1px solid #5460ec;
  margin: 15px 0px;
  opacity: 30%;
`;

export const DayDivider = styled.div`
  flex: 1;
  min-height: 32px;
  border-left: 1px solid #5460ec;
  margin: 15px 0px;
  opacity: 30%;
`;

export const ActivityPointerCotainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  .day-time-container {
    position: relative;
    top: -15px;
    width: 100%;
    height: 100%;
  }
  .activity-time-container {
    top: 10px;
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

// export const ActivityName = styled(Typography)`
//   && {
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     margin-left:18px;
//   }
// `;

export const ActivityName = styled(Typography)`
  && {
    margin-left: 18px;
  }
`;

export const UploadPhotos = styled.img`
  z-index: 999;
  align-self: flex-start;
  cursor: pointer;
  height: 18px;
  width: 18px;
`;

export const StyledLink = styled.a`
  font-family: Open Sans;
  margin-right: 10px;
  text-decoration: none;
  z-index: 999;
  align-self: flex-start;
  cursor: pointer;
  color: #00c099;
  font-size: 14px;
  line-height: 1;
`;

export const StyledButton = styled(Button)`
  && {
    color: ${(props) =>
      props.isGreen === 1 ? props.theme.lightGreen : props.theme.primaryColor};
    font-weight: 300;
    min-width: auto;
    font-size: 0.7rem;
    white-space: nowrap;
    padding: 7px;
    letter-spacing: 0.05em;
    line-height: 1;
    &:hover {
      background-color: transparent;
    }
  }
`;

export const StyledChecbox = styled(({ ...other }) => (
  <Checkbox
    icon={<CheckBoxOutlineBlankIcon style={{ fontSize: "18px" }} />}
    checkedIcon={<CheckBoxIcon style={{ fontSize: "18px" }} />}
    {...other}
    classes={{
      checked: "checked",
    }}
  />
))`
  && {
    z-index: 999;
    margin-right: 10px;
    padding: 0px;
    align-self: flex-start;
    color: #00c099;
    &.checked {
      color: #00c099;
    }
  }
`;

export const DrivingTime = styled(Typography)`
  && {
    font-size: 8px;
    color: ${(props) => props.theme.secondaryWhite};
    white-space: nowrap;
  }
`;

export const DrivingTimeContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: absolute;
  img {
    margin-right: 5px;
    align-self: flex-start;
  }
`;

export const Container = styled.div`
  border-left: 1px solid rgba(255, 255, 255, 0.37);

  .activity-container {
    padding-bottom: 10px;
  }
`;

// styles for Notes

export const NotesContainer = styled(Grid)`
  && {
    background-color: #333;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const NotesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  padding: 0px 10px;
`;

export const NotesActionContainer = styled(Grid)`
  && {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const NotesBody = styled(Grid)`
  && {
    padding-rigth: 35px;
  }
`;

export const CustomIconButton = styled(IconButton)`
  && {
    color: #00c099;
    width: 18px;
    height: 18px;
    marginleft: 5px;
  }

  .icon-style {
    padding: 0;
    color: #00c099;
    width: 18px;
    height: 18px;
  }
`;
