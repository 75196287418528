import { connect } from "react-redux";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { reduxForm, getFormValues } from "redux-form";
import { editProfileApi } from "../services";
import { enqueueSnackbar } from "../../../Master/Notifier/duck";
import { UPDATE_USER_DATA } from "../../../Onboarding/duck";
import { compress } from "../../../common/utils";
import { API_ERROR, PROFILE_UPDATED_SUCCESS } from "../../../constants";

const mapStateToProps = (state) => {
  return {
    values: getFormValues("profile-form")(state),
    auth: state.auth,
    formvalues: state.auth.userData,
  };
};

const submitProfileForm = (values, dispatch, props) => {
  const {
    auth: { token },
  } = props;
  let lastName = "";
  if (values.fullName.split(" ").length > 1) {
    let splitName = values.fullName.split(" ");
    splitName.splice(0, 1);
    lastName = splitName.join(" ");
  }

  let filePromise = Promise.resolve(null);
  if (values.prof_pic && values.prof_pic.file) {
    filePromise = compress(values.prof_pic.file, 288);
  }

  filePromise.then((compressFiled) => {
    const formValues = {
      user_name: values.user_name,
      change_email: values.email,
      currency: values.currency,
      change_mob: values.mobile, //? values.mobile : "",
      mobile: values.mobile,
      first_name: values.fullName ? values.fullName.split(" ")[0] : "",
      last_name: lastName,
      country: values.country,

      upl: compressFiled,
      desc: values.desc ? values.desc : "",
      profile_type: values.profile_type,
    };
    let formData = new FormData();
    Object.keys(formValues).forEach((key) => {
      return formData.append([key], formValues[key]);
    });

    return editProfileApi(formData, token)
      .then((res) => {
        if (res.data.message === "Success") {
          // if (userData.email !== formValues.change_email) {
          //   dispatch(
          //     enqueueSnackbar({
          //       message: "Verification link sent to new email",
          //       variant: "success"
          //     })
          //   );
          // }
          formValues.profile_pic = values.prof_pic
            ? values.prof_pic.data
            : values.profile_pic;
          dispatch({
            type: UPDATE_USER_DATA,
            payload: formValues,
          });
          dispatch(
            enqueueSnackbar({
              message: PROFILE_UPDATED_SUCCESS,
              variant: "success",
            })
          );
        } else {
          dispatch(
            enqueueSnackbar({
              message: API_ERROR,
              variant: "error",
            })
          );
        }
      })
      .catch((err) => {
        if (err.response.data.status === 409) {
          console.log(err);
          return dispatch(
            enqueueSnackbar({
              message: "User name already taken",
              variant: "error",
            })
          );
        }
        dispatch(
          enqueueSnackbar({
            message: err.response.data.message,
            variant: "error",
          })
        );
      });
  });
};

const EditProfileFormHandlers = compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: "profile-form",
    onSubmit: submitProfileForm,
  }),
  connect(mapStateToProps, null),
  lifecycle({
    componentDidMount() {
      this.props.initialize(
        Object.assign(this.props.formvalues, {
          fullName:
            this.props.formvalues.first_name +
            " " +
            this.props.formvalues.last_name,
        })
      );
    },
  }),
  withState("tabIndex", "setTabIndex", 0),
  withHandlers({
    onChange: (props) => (e, val) => {
      const { setTabIndex } = props;
      setTabIndex(val);
    },
  })
);

export default EditProfileFormHandlers;
