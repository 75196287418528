import { axiosInst, axiosConfig } from "../../axios";
import axios from "axios";

export const getTripApi = (tripType, name, token, pageNo) => {
  return axiosInst.post(
    "/triplist",
    { type: tripType, name, pageno: pageNo, perpage: 10 },
    axiosConfig(token)
  );
};

export const getUserTripsApi = (token, id, pageNo, name) => {
  if (token) {
    return axiosInst.post(
      "/triplistuser",
      { perpage: 10, pageno: pageNo, user_name: id, name },
      // { perpage: 10, pageno: pageNo, user_id: id, name },
      axiosConfig(token)
    );
  }
  return axiosInst.post("/triplistuser", {
    perpage: 10,
    pageno: pageNo,
    user_name: id,
    name,
  });
};

export const deleteTripApi = (trip_id, token) => {
  return axiosInst.post(
    "/deletetrip",
    {
      trip_id: String(trip_id),
    },
    axiosConfig(token)
  );
};

export const getProfileApi = (token) => {
  return axiosInst.post("/viewprofile", {}, axiosConfig(token));
};

export const getCheckinApi = (token) => {
  return axiosInst.post("/checkin", null, axiosConfig(token));
};

export const getUserCheckinApi = (token, id) => {
  return axiosInst.post(
    "/profileofotheruser",
    {
      // user_id: id
      user_name: id,
    },
    axiosConfig(token)
  );
};
