import React from 'react'
import { Field } from 'redux-form'
import { required } from '../../common/FormFields/validations'
import ReduxFormTextField from '../../common/FormFields/TextField'
import ResponsiveDialog from '../../common/ResponsiveDialog'
import { OnboardingWrapper } from '../OnboardingWrapper'
import '../index.scss'
import { Typography } from '@material-ui/core'
import LoadingButton from '../../common/LoadingButton'

const AdduserPure = (props) => {
  const { submitting, handleSubmit, reset, isOpen, setIsOpen } = props

  return (
    <ResponsiveDialog
      showActions={false}
      showTitle={false}
      contentClass="onboarding-modal-content"
      dialogClass="onboarding-dialog"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      renderContent={() => {
        return (
          <OnboardingWrapper
            handleClose={() => {
              reset(AdduserPure)
              setIsOpen(false)
            }}
          >
            <div className="onboarding-main-content">
              <Typography gutterBottom variant="h4" color="secondary">
                Add new User
              </Typography>
              <form className="onboarding-form" onSubmit={handleSubmit}>
                <div className="form-item">
                  <Field
                    name="username"
                    validate={required}
                    required
                    component={ReduxFormTextField}
                    label="Username"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="password"
                    validate={required}
                    required
                    type={"password"}
                    component={ReduxFormTextField}
                    label="Password"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="first_name"
                    component={ReduxFormTextField}
                    label="First Name"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="last_name"
                    component={ReduxFormTextField}
                    label="Last Name"
                  />
                </div>
                <div className="form-item">
                  <Field
                    name="email"
                    type="email"
                    component={ReduxFormTextField}
                    label="Email"
                  />
                </div>
                <div>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type={'submit'}
                    submitting={submitting}
                  >
                    Submit
                  </LoadingButton>
                </div>
              </form>
            </div>
          </OnboardingWrapper>
        )
      }}
    />
  )
}

export default AdduserPure
